import React from 'react';
import PropTypes from 'prop-types';

import Filter from 'ui/Filter';
import SearchableFilter from 'ui/SearchableFilter';

const SubscriptionFilter = ({ onChange }) => {

  return (
    <div className='filter-group'>
        <SearchableFilter
          label="pass"
          onChange={onChange}
        />
        <Filter
          onChange={onChange}
          label="status"
          table={'subscriptions'}
        />
    </div>
  );
};

SubscriptionFilter.propTypes = {
  titles: PropTypes.array,
  onChange: PropTypes.func,
};

export default SubscriptionFilter;
