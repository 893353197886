import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { visibilityOptions } from 'utils/offersFormHelper';

const VisibilitySelector = ({ visible, onChange }) => {

  return (
    <FormControl>
      <InputLabel color="warning">Visibility</InputLabel>
      <Select
        label="Visibility"
        name="visible"
        color="warning"
        size="small"
        fullWidth
        value={ visible }
        onChange={ onChange }
      >
        { visibilityOptions.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
          }
        )}
      </Select>
    </FormControl>
  );
};

VisibilitySelector.propTypes = {
  onChange: PropTypes.func,
  visible: PropTypes.string
};

export default VisibilitySelector;