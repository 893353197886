import React from 'react';
import PropTypes from 'prop-types';

import { t } from "i18n/index";

import Button from 'ui/Button';

import CustomModal from 'components/CustomModal';

import DoneIcon from '@mui/icons-material/Done';

const ProductContentModal = ({productContent, onClose}) => {

  const { content_text, content_link } = productContent;

  const content =
          { 
              title: <div>{t('profile:perks:redeemedSuccessfully')} <DoneIcon color='success' sx={{verticalAlign: 'text-top'}}/></div> ,
              content: content_text,
              button: content_link 
                          ? <Button highlighted onClick={ () => window.open(content_link, '_blank') }>Continue</Button>
                          : null,
              supportMarkdown: true
          }

  return (
    <CustomModal handleClose={onClose} customModalContent={content} />
  );
};

ProductContentModal.propTypes = {
  productContent: PropTypes.object,
  onClose: PropTypes.func
};

export default ProductContentModal;