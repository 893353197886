import React from 'react';
import PropTypes from 'prop-types';

import TextField from "@mui/material/TextField";

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const DateInputField = ({ attribute, onChange, label, object, disablePast=false, setUnsavedChanges}) => {

    const handleDateTimeChange = (newValue) => {
        if (setUnsavedChanges) setUnsavedChanges(true);
        let newOffer = { ...object, [attribute]: newValue };
        onChange(newOffer);
    };
    
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker label={ label }
                            value={ object[attribute] ? object[attribute] : null }
                            onChange={ handleDateTimeChange }
                            disablePast={ disablePast}
                            renderInput={ (params) => <TextField {...params} color='warning' fullWidth size='small'/> }
            />
        </LocalizationProvider>
    );
};

DateInputField.propTypes = {
    attribute: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    object: PropTypes.object,
    disablePast: PropTypes.bool,
    setUnsavedChanges: PropTypes.func
};

export default DateInputField;