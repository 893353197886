import React from 'react';
import PropTypes from 'prop-types';

import TicketCard from 'components/Cards/TicketCard';

const TicketsGrid = ({ data }) => {
    
    return (
        <div className='tickets-grid'>
            {data.map((item, index) => {
            return <TicketCard item={ item } 
                              key={ index } 
                              />
            })}
        </div>
    );
};

TicketsGrid.propTypes = {
    data:PropTypes.array,
};

export default TicketsGrid;
