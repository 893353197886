import React from 'react';
import PropTypes from 'prop-types';

import SearchableFilter from 'ui/SearchableFilter';
import Filter from 'ui/Filter';

const OrderFilter = ({ onChange }) => {

  return (
    <div className='filter-group'>
        <SearchableFilter
          label={"organization"}
          onChange={onChange}
        />
        <Filter
            onChange={onChange}
            label="status"
            table={'orders'}
          />
    </div>
  );
};

OrderFilter.propTypes = {
  onChange: PropTypes.func,
};

export default OrderFilter;
