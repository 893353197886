import React from "react";

import { getDateFormat } from "utils/tables/getDateFormat";
import { getDateSelector } from "utils/tables/getDateSelector";

import Checkbox from "@mui/material/Checkbox";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { IconButton, Tooltip } from "@mui/material";
import ZoomInIcon from '@mui/icons-material/ZoomIn';

const renderEditCell = (
  row,
  editingEmail,
  selectedTickets,
  handleSelectionChange
) =>
  editingEmail && (
    <Checkbox
      checked={selectedTickets.includes(row)}
      onChange={() => handleSelectionChange(row)}
      color="warning"
    />
  );

const renderRedeemState = ( row ) => {
  return row.completed_at
    ? <Tooltip title="Checked In"><DoneIcon color="success" size="small"/></Tooltip>
    : <Tooltip title="Not Checked In"><CloseIcon color="error" size="small"/></Tooltip> ;
};

const renderSeeDetails = ( onClick ) => {
  return <Tooltip title="See Details"><IconButton onClick={onClick} size="small"><ZoomInIcon /></IconButton></Tooltip>
};

export const ticketsColumns = ( handleOpenTicketDetails, editingEmail=false, selectedTickets=[], handleSelectionChange=null ) => {
  return [
    {
      field: "redeem_state",
      name: "",
      sortable: true,
      width: "56px",
      style: {
        cursor: "default",
      },
      selector: (row) => renderRedeemState( row ),
    },
    {
      field: "details",
      name: "",
      width: "56px",
      allowOverflow: true,
      style: {
        cursor: "default",
      },
      selector: (row) => renderSeeDetails( () => handleOpenTicketDetails(row) ),
    },
    {
      field: "email",
      name: "Email Address",
      sortable: true,
      width: "200px",
      style: {
        cursor: "default",
      },
      selector: (row) => row.user_email,
    },
    {
      field: "name",
      name: "Name",
      sortable: true,
      width: "200px",
      style: {
        cursor: "default",
      },
      selector: (row) => row.user_name,
    },
    {
      field: "title",
      name: "Title",
      sortable: true,
      width: "200px",
      style: {
        cursor: "default",
      },
      selector: (row) => row.title,
    },
    {
      field: "completed_at",
      name: "Checked In",
      sortable: true,
      width: "150px",
      style: {
        cursor: "default",
      },
      selector: (row) => getDateSelector(row.completed_at),
      format: (row) => getDateFormat(row.completed_at, 'DD/MM HH:mm'),
    },
    {
      cell: (row) =>
        renderEditCell( row, editingEmail, selectedTickets, handleSelectionChange ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      style: {
        cursor: "pointer",
      },
      width: "56px",
    },
  ];
};
