import React from 'react';
import PropTypes from 'prop-types';

import TableButton from 'components/TableButton';

import copyToClipboard from 'utils/copyToClipboard';
import { useToast } from 'utils/context/ToastContext';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PreviewIcon from '@mui/icons-material/Preview';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import KeyIcon from '@mui/icons-material/Key';

import moment from 'moment';

const ActionButtons = ({event, loading, organizationId, history, handleConfirmDeleteModal, renderCheckInButton, openCheckinCredentialsModal}) => {

    const { status, id, external_url, has_ticket, start_date, ticketing } = event;

    const { setOpen, setMessage, setSeverity } = useToast();

    const draft = status === 'draft';
    const isPastEvent = moment(start_date).utc().startOf('day') < moment().utc().startOf('day');

    const handlePreview = () => {
      window.open(external_url, '_blank');
    };

    const handleUrlCopy = () => {
      copyToClipboard(external_url, setOpen, setSeverity, setMessage)
    };

    const actionButtonsData = [
        {
          label: 'Copy URL',
          icon: ContentCopyIcon,
          action: handleUrlCopy,
          disabled: loading || draft,
        },
        {
          label: 'Preview',
          icon: PreviewIcon,
          action: handlePreview,
          disabled: loading,
        },
        {
          label: 'Edit',
          icon: EditIcon,
          action: () => history.push(`/organizations/${organizationId}/events/${id}/edit`),
          disabled: loading,
        },
        {
          label: 'Delete',
          icon: DeleteIcon,
          action: () => handleConfirmDeleteModal(event),
          disabled: loading,
        },
      ];

    if(ticketing){
        actionButtonsData.unshift(
          {
            label: 'Add Ticket',
            icon: AddCircleOutlineOutlinedIcon,
            action: () => history.push(`/organizations/${organizationId}/perks/new?eventId=${id}`),
            disabled: loading || isPastEvent,
          },
          {
            label: 'Check In URL && Code',
            icon: KeyIcon,
            action: () => openCheckinCredentialsModal(event),
            disabled: loading || draft,
          },
        )
    }

    if(has_ticket && !renderCheckInButton) {
      actionButtonsData.unshift(
        {
          label: 'Tickets',
          icon: LocalActivityIcon,
          action: () => history.push(`/organizations/${organizationId}/events/${id}/tickets`),
          disabled: loading,
        }
      )
    }

  return (
    <div>
        {actionButtonsData.map((data) => (
            <TableButton
                key={data.label}
                title={data.label}
                Icon={data.icon}
                action={data.action}
                disabled={data.disabled}
            />
        ))}
    </div>
  );
};

ActionButtons.propTypes = {
    event: PropTypes.object,
    loading: PropTypes.bool,
    organizationId: PropTypes.number,
    history: PropTypes.object,
    handleConfirmDeleteModal: PropTypes.func,
    renderCheckInButton: PropTypes.bool,
    openCheckinCredentialsModal: PropTypes.func
};

export default ActionButtons;