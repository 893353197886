import React, { useMemo } from "react";
import { useSuspense, NetworkErrorBoundary } from "@rest-hooks/react";
import { useParams } from "react-router-dom";

import TicketsList from "components/TicketsList";

import TicketResource from "resources/checkin/TicketResource";
import EventResource from "resources/checkin/EventResource";
import NetworkError from "./NetworkError";
import PageTop from "components/PageTop";

const TicketsContent = () => {    

  const { eventUuid } = useParams();
  const requestParams = { event_uuid: eventUuid };

  //fetch event details to get ticket titles, event title, external_url and organization_logo_url
  const event = useSuspense(EventResource.detail(), { id: eventUuid });

  const pageInfo = useMemo(
    () => ({
      name: event?.title || "Tickets",
      description: "Manage your tickets and check-ins here",
    }),
    [event]
  );
      
  return (
    <main>
        <div className="content">

        <PageTop pageInfo={ pageInfo } />

        <TicketsList TicketResource={TicketResource} ticketResourceParams={requestParams} event={event}/>

        </div>
    </main>
  );
};

const Tickets = () => {
  return (
    <NetworkErrorBoundary fallbackComponent={NetworkError}>
      <TicketsContent />
    </NetworkErrorBoundary>  
  )
};

export default Tickets;
