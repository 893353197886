import React from 'react';
import PropTypes from 'prop-types';

import { getFilterOptions, getUserFriendlyIdentifier } from 'utils/filtersHelpers';

import { Autocomplete, TextField } from '@mui/material';

const Filter = ({ onChange, label, table }) => {

  const handleSelection = (event, newValue) => {
    onChange(label, newValue?.identifier);
  };

  const renderOption = (props, option) => <li {...props}>{getUserFriendlyIdentifier(table, option.identifier)}</li>;
  const renderInput = (params) => <TextField {...params} label={`Filter by ${label}`} color="warning" />;

  return (
    <Autocomplete
      size="small"
      selectOnFocus
      blurOnSelect
      clearOnBlur
      clearOnEscape	
      handleHomeEndKeys
      sx={{ width: 240 }}
      options={getFilterOptions(table)}
      onChange={handleSelection}
      renderOption={renderOption}
      renderInput={renderInput}
      getOptionLabel={(option) => getUserFriendlyIdentifier(table, option.identifier)}
    />
  );
};

Filter.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    table: PropTypes.string
};

export default Filter;
