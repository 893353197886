import moment from 'moment';
import 'moment/locale/pt';
import i18n from 'i18next';

const dateFormats = {
    "datetime":  "llll",
    "date":      "ll",
    "day":       "DD",
    "month":     "MMM",
    "time":      "LT",
};

const formattedDate = (date, method='fromNow', fallback='Never') => {

  if (date) {
    const parsedDate = moment(date).locale(i18n.language);

    const format = dateFormats[method];
    return format ? parsedDate.format(format) : parsedDate[method]();

  } else {
    return fallback;

  }
};


export default formattedDate;
