import React from "react";
import PropTypes from "prop-types";

import VideoPreview from "components/VideoPreview";

import useSliderController from 'utils/useSliderController';

const Image = ({ image_url, video_url, media_type, cropped, onClick }) => {

  const {handleImageClick, SliderComponent} = useSliderController([image_url]);

  const handleClick = onClick ? onClick : () => handleImageClick(0);

  return (
    media_type == "video" 
      ? <VideoPreview className="image" url={ video_url } poster={ image_url } playAudio={false} />
      : <>
          <img className={`image ${cropped ? 'cropped' : ''} ${onClick ? 'pointer' : ''}`}
            src={ image_url }
            onClick={ handleClick }/>
          <SliderComponent />
        </>
  );
};

Image.propTypes = {
  image_url: PropTypes.string,
  video_url: PropTypes.string,
  media_type: PropTypes.string,
  cropped: PropTypes.bool,
  onClick: PropTypes.func
};

export default Image;
