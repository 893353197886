import React from 'react';
import PropTypes from 'prop-types';

import Filter from 'ui/Filter';
import SearchableFilter from 'ui/SearchableFilter';

const TicketFilter = ({ onChange }) => {

  return (
    <div className='filter-group'>
        <SearchableFilter
          onChange={onChange}
          label="ticket"
        />
        <Filter
          onChange={onChange}
          label="status"
          table={'tickets'}
        />
    </div>
  );
};

TicketFilter.propTypes = {
  onChange: PropTypes.func,
};

export default TicketFilter;
