import React, { createContext, useContext, useState } from 'react';

import ErrorModal from 'components/ErrorModal';

import { capitalize } from 'utils/capitalize';

import { t }from "i18n/index";

const ErrorContext = createContext();

export const useErrorController = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const validate = (record, validateFunction) => {
    setLoading(true)
    validateFunction(record);
  };

  const handleError = async (error) => {
    let errorMessage;
    if (typeof error === 'string') {
      errorMessage = [error];

    } else {
      errorMessage = await getApiErrorMessage(error);
    }
    const message = errorMessage ? errorMessage : t('errors:default');
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  const getApiErrorMessage = async (error) => {
    if (404 === error.response.status) {
      if (error.response.url.includes("authentication")) {
        return [ t('errors:unableToValidateCredentials') ];
      } else {
        return [ t('errors:notFound') ];
      }
    } else if (401 === error.response.status) {
      localStorage.clear();
      return [ `${t('errors:unableToValidateCredentials')}. ${t('errors:pleaseRefresh')}` ];
    } else {
      const response = await error.response.json();
      const errorMessages = [];
      if (response.errors) {
        Object.keys(response.errors).forEach((field) =>
          errorMessages.push(`${capitalize(field)}: ${response.errors[field].join(', ')}. `)
        );
      }
      return errorMessages;
    }
  };

  const handleCloseErrorModal = () => {
    setErrorMessage(null);
    setOpenErrorModal(false);
    setLoading(false);
  };

  const ErrorModalComponent = () => {
    if (!errorMessage) return null;
    return (
       <ErrorModal message={errorMessage} open={openErrorModal} onClose={handleCloseErrorModal} />
    );
  };

  return {
    hasErrorMessage: !!errorMessage,

    loading,
    setLoading,

    validate,
    handleError,
    handleCloseErrorModal,

    ErrorModalComponent,
  };
};

// eslint-disable-next-line react/prop-types
export const ErrorProvider = ({ children }) => {
  const errorController = useErrorController();

  return (
    <ErrorContext.Provider value={errorController}>
      {children}
      {errorController.ErrorModalComponent()}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  return useContext(ErrorContext);
};
