import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { useSuspense } from "@rest-hooks/react";

import RewardResource from "resources/organization/RewardResource";

import TierSelector from './TierSelector';

import Button from 'ui/Button';

import { emptyCoupon } from "utils/offersFormHelper";

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';

export const Coupons = ({coupons, onChange, disabled}) => {

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const rewards = useSuspense(RewardResource.list(), { organization_id: organizationId});
  const tiers   = rewards.map(({ id, title }) => ({ id, title }));


  const handleCouponChange = (event, index) => {
    const newValue = event.target.value >= 100 ? 99 : event.target.value;
    let data = [...coupons];
    data[index].percent_off = newValue;

    onChange({target: {name: "coupons", value: data }});
  };

  const handleTierSelection = (event, value, reason, index) => {

    let data = [...coupons];

    data[index].tier_id = value?.id || "";
    data[index].tier_title = value?.title || "";

    if (reason === "clear") {
      data.splice(index, 1);
    }

    onChange({ target: { name: "coupons", value: data } });
  };

  const addCoupon = (e) => {
    e.preventDefault();
    let data = coupons ? [...coupons] : [];
    data.push({ ...emptyCoupon });
    onChange({target: {name: "coupons", value: data }});
  };

  return <>

    {coupons && coupons.map((coupon, index) => {
      return (
          <div className="row" key={`coupon-${index}`} >
            <TierSelector
              tier_title={coupon.tier_title}
              tiers={tiers}
              coupons={coupons}
              index={index}
              onTierChange={(e, newValue, reason) => handleTierSelection(e, newValue, reason, index)} />

            <TextField
              fullWidth
              size='small'
              name="percent_off"
              label="Percentage Off"
              color="warning"
              value={coupon.percent_off}
              type="number"
              inputProps={{ inputMode: 'numeric' }}
              onChange={event => handleCouponChange(event, index)} />
          </div>
      );
    })}

    <Button text small icon={AddCircleOutlineOutlinedIcon} onClick={addCoupon} disabled={disabled}>Add Coupon</Button>
  </>;
};

Coupons.propTypes = {
  coupons: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default Coupons;
