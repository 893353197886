import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useSuspense } from '@rest-hooks/react';

import ProfileResource from 'resources/Profile/ProfileResource';
import ProductResource from 'resources/Profile/ProductResource';

import AuthModal from 'components/authForms/AuthModal';
import RedeemModal from 'components/RedeemModal';
import CheckoutModal from 'components/CheckoutModal';

import Card from 'ui/Card';
import Button from 'ui/Button';

import Header from './Header';
import Footer from './Footer';

import { usePreActionController } from 'utils/usePreActionController';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { t } from "i18n/index";

const SingleCard = ({event, organizationSlug}) => {

  const { title, description, start_date, end_date, location, image_url, tickets, show_view_all, slug } = event;

  const { ticketSlug } = useParams();
  const ticket = ticketSlug 
                    ? useSuspense(ProductResource.detail(), {organization_slug: organizationSlug, id: ticketSlug})
                    : null;

  const history = useHistory();
  const handleRedirect = () => {
    history.push(`/${organizationSlug}/events`);
  };

  const { show_application_fee} = useSuspense(ProfileResource.detail(), {id: organizationSlug});

  const {
    currentAction,
    modalsState,
    onAction,
    onSuccessfulAuth,
    onClose
  } = usePreActionController(organizationSlug, null, slug);
  
  return (
    <div className='events-content'>
      <Card 
          fullwidth
          header={
            <Header 
              image_url={image_url}
              title={title}
              start_date={start_date}
              end_date={end_date}
              location={location}
              tickets={ticket ? [ticket] : tickets} 
              onAction={onAction} 
              showApplicationFee={show_application_fee}
              />}
          footer={
            <Footer 
              location={location} />}
              description={description} />
      
      {show_view_all &&
        <Button 
          secondary 
          icon={ArrowBackIcon}
          minwidth 
          onClick={handleRedirect}>
            {t('literal:viewAll')}
          </Button>
      } 
         
      {modalsState.authModal &&
        <AuthModal 
          open={ modalsState.authModal }
          done={ onSuccessfulAuth }
          onClose={ onClose } 
          organizationSlug={ organizationSlug }
          allowPartialSignIn={ true } />}

      {modalsState.redeemModal && 
        <RedeemModal 
          product={currentAction.product}
          onClose={onClose}
          organizationSlug={organizationSlug}
          eventTitle={ title }
          /> }

      {modalsState.checkoutModal &&
        <CheckoutModal
          modalsState={modalsState}
          product={currentAction.product}
          onClose={onClose}
        />}
    </div>
  );
};

SingleCard.propTypes = {
  event: PropTypes.array,
  organizationSlug: PropTypes.string
};

export default SingleCard;
