import React, { useState } from "react";
import { useController, useSuspense } from "@rest-hooks/react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import MembershipResource from "resources/Profile/MembershipResource";
import ClaimResource from "resources/public/ClaimResource";
import ProfileResource from "resources/Profile/ProfileResource";
import SubscriptionResource from "resources/Profile/SubscriptionResource";

import AuthModal from "components/authForms/AuthModal";
import SocialMetaTags from 'components/SocialMetatags';

import CardGrid from "./CardGrid";
import FrequencySwitch from "./FrequencySwitch";
import ConnectModal from "./ConnectModal";

import Title from "ui/text/Title";
import Description from "ui/text/Description";

import UserName from "utils/localStorage/UserName";
import { useError } from "utils/useErrorController";
import { filterPassesByFrequency } from "utils/filterPassesByFrequency";
import useSubscriptionController from "utils/useSubscriptionController";
import { scrollToTop } from "utils/scrollToTop";
// import generateMetatags from "utils/generateMetatags";
// import { useSocialMetatags } from "utils/socialMetatagsController";

import { t } from "i18n/index";

import "./styles.css";

const Memberships = ({organization_title, organization_logo}) => {

  const {organizationSlug} = useParams();
  const { fetch } = useController();
  // const {setSocialMetatags} = useSocialMetatags();

  // useEffect(() => {
  //   generateMetatags('memberships', setSocialMetatags, organization_title, organization_logo);
  // }, []);

  scrollToTop();

  const [activeFrequency, setActiveFrequency] = useState("monthly");
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [claimToken, setClaimToken] = useState(null);
  const [claimWithNFT, setClaimWithNFT] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [openConnectModal, setOpenConnectModal] = useState(false);

  const tiers = useSuspense(MembershipResource.list(), {
    organization_slug: organizationSlug,
  });

  const issuedPass = tiers.find((pass) => pass.already_issued_to);
  const userSubscription = issuedPass && issuedPass.subscription_details;
  const paidSubscription = userSubscription && userSubscription.paid_subscription;
  const manualSubscription = userSubscription && userSubscription.manual_subscription;
  const nftSubscription = userSubscription && userSubscription.nft_subscription;

  const mustReactivate =
    userSubscription &&
    userSubscription.status === "expiring" &&
    !userSubscription.scheduled_change;

  const filteredPasses = filterPassesByFrequency(tiers, activeFrequency);

  const userName = UserName.read();
  const isLoggedIn = !!userName;
  const hasWalletAddress = tiers.length > 0 && tiers[0].has_wallet_address;

  const isDowngradingToFree = (is_downgrading, sellable) => {
    return is_downgrading && !sellable;
  }

  const { handleError, setLoading, loading } = useError();
  const {
    ConfirmModalComponent,
    setDowngradingToFree,
    setDowngradingToPaid,
    setReactivating,
  } = useSubscriptionController(userSubscription, handleError, mustReactivate);

  const handleClick = async (e, claim_token, claimWithNFT=false) => {
    try {
      e.preventDefault();
      if (nftSubscription) {
        handleError(t("errors:nftSubscription"));
      } else if (manualSubscription && paidSubscription) {
        handleError(t("errors:manualSubscription"));
      } else {
        setClaimToken(claim_token);
        setClaimWithNFT(claimWithNFT);

        const response = await fetch(MembershipResource.detail(), {organization_slug: organizationSlug, id: claim_token});
        const { already_issued_to, is_downgrading, sellable, id } = response;

        if (already_issued_to) {
          handleError(t('errors:passAlreadyIssued'));
        } else if (mustReactivate) {
          setReactivating(true);
        } else if (isDowngradingToFree(is_downgrading, sellable)) {
          setDowngradingToFree(true);
        } else if (is_downgrading) {
          setDowngradingToPaid({
            active_frequency: activeFrequency,
            reward_id: id,
          });
        } else if (isLoggedIn) {
            if (!sellable || (claimWithNFT && hasWalletAddress)) {
              handleClaim(claim_token, claimWithNFT);
            } else if (claimWithNFT && !hasWalletAddress) {
              setOpenConnectModal(true);
            } else {
              setOpenClaimModal(true);
            }
        } else if (claimWithNFT) {
          setOpenConnectModal(true);
        } else {
          setOpenAuthModal(true);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleClaim = async (claimToken, claimingWithNFT) => {
    try {
      setLoading(true);
      if (claimingWithNFT) {
        await fetch(SubscriptionResource.create(), {organization_slug: organizationSlug }, { claim_token: claimToken });
      } else {
        await fetch(ClaimResource.create(), { id: claimToken });
      }
      await fetch(MembershipResource.list(), {organization_slug: organizationSlug});
      await fetch(ProfileResource.detail(), {id: organizationSlug});
      // no need to refresh content as that is being fetched on render an not using useSuspense
      setOpenClaimModal(true);
      setLoading(false)
    } catch (error) {
      handleError(error);
    }
  };

  const handleSuccessfulAuth = async () => {
    setOpenAuthModal(false);
    setOpenConnectModal(false);

    const response = await fetch(MembershipResource.detail(), {organization_slug: organizationSlug, id: claimToken});
    const { already_issued_to, is_downgrading, sellable, id } = response;

    if (already_issued_to) {
      handleError(t('errors:passAlreadyIssued'));
    } else if (mustReactivate) {
      setReactivating(true);
    } else if (isDowngradingToFree(is_downgrading, sellable)) {
      setDowngradingToFree(true);
    } else if (is_downgrading) {
      setDowngradingToPaid({
        active_frequency: activeFrequency,
        reward_id: id,
      });
    } else {
      if (!sellable || claimWithNFT) {
        handleClaim(claimToken, claimWithNFT);
      } else {
        setOpenClaimModal(true);
      }
    }

    await fetch(MembershipResource.list(), {organization_slug: organizationSlug});
  };

  const showClaimModal = (claim_token) =>
    openClaimModal && claim_token === claimToken;

  return (
    <div className="memberships-content">
      <SocialMetaTags title={ `${organization_title} Membership Options` }
                      description={ `Check out and sign up to ${organization_title}'s membership options.` }
                      image_url={ organization_logo }
                      external_url={window.location.href}
      />

      <Title>{t('literal:memberships')}</Title>

      <Description secondary>
        {issuedPass
          ? t('profile:memberships:memberPageDescription')
          : t('profile:memberships:pageDescription')}
      </Description>

      <FrequencySwitch
        tiers={tiers}
        activeFrequency={activeFrequency}
        onFrequencyChange={setActiveFrequency}
      />

      <CardGrid
        loading={loading}
        activeFrequency={activeFrequency}
        userSubscription={userSubscription}
        filteredPasses={filteredPasses}
        showClaimModal={showClaimModal}
        handleCloseClaimModal={() => setOpenClaimModal(false)}
        handleClick={handleClick}
        handleError={handleError}
        claimWithNFT={claimWithNFT}
        organizationSlug={organizationSlug}
      />

      <ConnectModal 
        open={openConnectModal}
        onClose={() => setOpenConnectModal(false)}
        done={handleSuccessfulAuth}
        isLoggedIn={isLoggedIn}
        handleError={handleError}
        loading={loading}
        setLoading={setLoading}
        organizationSlug={organizationSlug}
      />

      <AuthModal
        open={openAuthModal}
        onClose={() => setOpenAuthModal(false)}
        done={handleSuccessfulAuth}
      />

      <ConfirmModalComponent />
    </div>
  );
};

Memberships.propTypes = {
  organization_title: PropTypes.string,
  organization_logo: PropTypes.string,
};

export default Memberships;
