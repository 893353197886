import React from 'react';
import { useParams } from "react-router-dom";

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'

import RewardInstancesTabContent from './RewardInstancesTabContent';
import OfferInstancesTabContent from './OfferInstancesTabContent';
import OrdersTabContent from './OrdersTabContent';
import NewslettersTabContent from './NewslettersTabContent';
import StatsTabContent from './StatsTabContent';
import TicketsTabContent from './TicketsTabContent';

const pageInfo = {
  name: "Reports",
  description: "Track your club growth",
};

const RewardInstance = () => {
  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  return (
    <Layout context='teacher'
            activeMenuItem='analytics'
            pageInfo={pageInfo}>

      <TabPanels labels={[  "Visits", "Newsletters", "Sold Tickets", "Sold Products", "Membership Passes", "Orders"]} >

        <StatsTabContent key={0} organizationId={organizationId} />

        <NewslettersTabContent key={1} organizationId={organizationId} />

        <TicketsTabContent key={2} organizationId={organizationId} />

        <OfferInstancesTabContent key={3} organizationId={organizationId} />

        <RewardInstancesTabContent key={4} organizationId={organizationId} />

        <OrdersTabContent key={5} organizationId={organizationId} />

      </TabPanels>
    </Layout>
)};

export default RewardInstance;
