import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Subtitle = ({children, highlighted}) => {
  
  const classes = ['ui-subtitle'];
  if (highlighted) classes.push('ui-highlighted-text');

  return (
    <h2 className={classes.join(' ')}>{children}</h2>
  )
};

Subtitle.propTypes = {
  children: PropTypes.string,
  highlighted: PropTypes.bool
};

export default Subtitle;