import { validateStringField } from "utils/validations/validateStringField";

const handleValidation = (post) => {
    const { title, start_date  } = post;
    validateStringField(title, "a valid title");
    validateStartDate(start_date)
};

const validateStartDate = (date) => {
    if (!date) {
        throw "Start date must not be empty.";
    }
};

    
export default handleValidation;