import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import ProductResource from 'resources/Profile/ProductResource';
import ProfileResource from 'resources/Profile/ProfileResource';
import EventResource from 'resources/Profile/EventResource';

import ProductContentModal from './ProductContentModal';
import NotEnoughPointsModal from './NotEnoughPointsModal';

import { useError } from 'utils/useErrorController';

import ConfirmationModal from './ConfirmationModal';

const defaultModalState = {
  notEnoughPointsModal: false,
  confirmationModal: false,
  // productContentModal: false,
};

const RedeemModal = ({product, onClose, organizationSlug, activeTag=null, eventTitle=null}) => {

  const [productContent, setProductContent] = useState(null);
  const [modalsState, setModalsState] = useState(defaultModalState);
  const [userPoints, setUserPoints] = useState(null);

  const {setLoading, handleError} = useError();
  const {fetch} = useController();

  const handleSuccessfulRedeem = async (content_text, content_link) => {
    await fetch(ProfileResource.detail(), {id: organizationSlug});
    await fetch(ProductResource.list(), {organization_slug: organizationSlug, tag_id: activeTag?.id});
    await fetch(EventResource.list(), {organization_slug: organizationSlug, all: true});
    setProductContent({content_text, content_link});
    setLoading(false);
  };

  const handleClose = () => {
    setProductContent(null);
    onClose();
  };

  const handleRedeemRequest = () => {
    if (product.points > 0) {
      checkPoints();
    } else {
      setModalsState({ ...modalsState, confirmationModal: true });
    }    
  };

  const checkPoints = async () => {
    try {
      setLoading(true);
      const { user_points } = await fetch(ProfileResource.detail(), {id: organizationSlug});
      setUserPoints(user_points);

      const enoughPoints = user_points >= product?.points;
      if (enoughPoints) {
        setModalsState({ ...modalsState, confirmationModal: true });

      } else { 
        setModalsState({ ...modalsState, notEnoughPointsModal: true });
      }

      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    handleRedeemRequest();
  }, []);

  return (
    <>
      {modalsState.notEnoughPointsModal && 
        <NotEnoughPointsModal 
          title={product.title}
          points={product.points}
          onClose={ handleClose }
        />}

      {modalsState.confirmationModal && 
        <ConfirmationModal
          open={modalsState.confirmationModal}
          product={product}
          organizationSlug={organizationSlug}
          userPoints={userPoints}
          eventTitle={eventTitle}
          onSuccessfulRedeem={handleSuccessfulRedeem}
          onClose={handleClose} />
        }

      {!!productContent &&
        <ProductContentModal
          productContent={productContent}
          onClose={handleClose}
        />}
    </>
  );
};

RedeemModal.propTypes = {
  product: PropTypes.object,
  redeemModal: PropTypes.bool,
  notEnoughPointsModal: PropTypes.bool,
  onClose: PropTypes.func,
  userPoints: PropTypes.number,
  organizationSlug: PropTypes.string,
  activeTag: PropTypes.object,
  eventTitle: PropTypes.string
};

export default RedeemModal;