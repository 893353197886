import React from 'react';
import PropTypes from 'prop-types';

import SearchableFilter from 'ui/SearchableFilter';

const TicketFilter = ({ onChange }) => {
  return (
    <div className='filter-group'>
        <SearchableFilter
          label={"organization"}
          onChange={onChange}
        />
        <SearchableFilter
          label={"event"}
          onChange={onChange}
        />
        <SearchableFilter
          label={"ticket"}
          onChange={onChange}
        />
    </div>
  );
};

TicketFilter.propTypes = {
  onChange: PropTypes.func,
};

export default TicketFilter;
