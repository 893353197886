import React, { useMemo } from "react";
import { useSuspense } from "@rest-hooks/react";
import { useParams } from "react-router-dom";

import Layout from 'components/layouts';
import TicketsList from "components/TicketsList";

import TicketResource from "resources/organization/TicketResource";
import EventResource from "resources/organization/EventResource";

const Tickets = () => {    

  const { organizationId, eventId } = useParams();
  const requestParams = { organization_id: organizationId, event_id: eventId };

  //fetch event details to get ticket titles, event title, external_url and organization_logo_url
  const event = useSuspense(EventResource.detail(), { organization_id: organizationId, id: eventId });

  const pageInfo = useMemo(
    () => ({
      name: event?.title || "Tickets",
      description: "Manage your tickets and check-ins here",
    }),
    [event]
  );
      
  return (
    <Layout context="teacher" activeMenuItem="events" pageInfo={pageInfo}>
        <TicketsList TicketResource={TicketResource} ticketResourceParams={requestParams} event={event}/>
    </Layout>
  );
};

export default Tickets;