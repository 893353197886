import React from "react";
import { useParams } from "react-router-dom";
import { useSuspense } from "@rest-hooks/react";

import PostResource from "resources/organization/PostResource";
import OrganizationResource from "resources/organization/OrganizationResource";

import PostFormWrapper from "./PostFormWrapper";

const NewPost = () => {

  const pageInfo = {
    name: "Create new post",
  };

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const organization = useSuspense(OrganizationResource.detail(), { id: organizationId });
  const disableNewsletter = !organization.newsletter_sender_created;

  const postData = {
    body: '',
    reward_ids: ['public'],
    tag_ids: [],
    title: '',
    slug: '',
    status: 'draft',
    image_files: [],
    image_filenames: [],
    video_files: [],
    video_filenames: [],
    disable_newsletter: disableNewsletter,
  };

  const resource = PostResource.create();
  const resourceParams = { organization_id: organizationId };

  const toastMessage = 'Content created successfully';

  return (
    <PostFormWrapper
      pageInfo={pageInfo}
      organizationId={organizationId}
      postData={postData}
      resource={resource}
      resourceParams={resourceParams}
      toastMessage={toastMessage}
    />
  );
};

export default NewPost;
