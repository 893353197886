import React from 'react';
import PropTypes from 'prop-types';

import AttachedImage from './AttachedImage';

const PostGallery = ({ image_urls, onImageClick }) => {

    const gridTemplateColumns = `repeat(${Math.min(image_urls.length-1, 3)}, 1fr)` ;
    const gridTemplateRows = '1fr 100px';

    const overFourImages = image_urls.length > 4;
    const iterableArray = overFourImages ? image_urls.slice(0, 3) : image_urls.slice(0, 4);

  return (
    <>
        <div className='post-images' style={{gridTemplateColumns: gridTemplateColumns, gridTemplateRows: gridTemplateRows}}>
            {iterableArray.map((imageUrl, index) => (
                <AttachedImage key={`image-${index}`} imageUrl={imageUrl} onClick={() => onImageClick(index)} />
            ))}
            {overFourImages && 
                <div className='last-image-container' onClick={() => onImageClick(2)}>
                    <AttachedImage key={`image-2`} imageUrl={image_urls[3]} last={true} />
                    <p>+{image_urls.length - 4}</p>
                </div>
            }
        </div>
    </>
  );
};

PostGallery.propTypes = {
    image_urls: PropTypes.array,
    onImageClick: PropTypes.func,
};

export default PostGallery;