import React from 'react'
import PropTypes from 'prop-types'

import QRCode from 'qrcode.react';
import logo from 'assets/images/logo.png';

const QRCodeGenerator = ({url, image_url}) => {

    const qrCode = (
        <QRCode id='qrCodeId'
                size={300}
                value={url}
                bgColor='white'
                fgColor='black'
                level='H'
                includeMargin={true}
                imageSettings={{
                  src: image_url ? image_url : logo,
                  excavate: true,
                  width: 100,
                  height: 100,
                  padding: 10,
                }}
        />
      );

  return (
    <div style={{textAlign: 'center'}}>{ qrCode }</div>
  );
};

QRCodeGenerator.propTypes = {
  url: PropTypes.string,
  image_url: PropTypes.string
};

export default QRCodeGenerator;
