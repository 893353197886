import React from 'react';
import PropTypes from 'prop-types';

import Subtitle from "ui/text/Subtitle";
import Date from './Date';
import Location from './Location';

const CardHeader = ({title, start_date, end_date, location, grid=false}) => {
  return (
    <div className="event-header">

      <Subtitle>{title}</Subtitle>
      
      <Date start_date={start_date} end_date={end_date} grid={grid} />

      {location && <Location location={location} />}
    
    </div>
  );
};

CardHeader.propTypes = {
    title: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    location: PropTypes.string,
    grid: PropTypes.bool
};

export default CardHeader;