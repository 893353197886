import React from "react";
import { useParams } from "react-router-dom";

import EventResource from "resources/organization/EventResource";

import EventFormWrapper from "./EventFormWrapper";

const NewEvent = () => {

  const pageInfo = {
    name: "Create new event",
  };

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const eventData = {
    title: '',
    desciption: '',
    start_date: null,
    end_date: null,
    location: '',
    status: 'draft',
    ticketing: true,
    external_url: '',
    ticket_price: '',
    slug: '',
  };

  const resource = EventResource.create();
  const resourceParams = { organization_id: organizationId };

  const toastMessage = 'Event created successfully';

  return (
    <EventFormWrapper
      pageInfo={pageInfo}
      organizationId={organizationId}
      eventData={eventData}
      resource={resource}
      resourceParams={resourceParams}
      toastMessage={toastMessage}
    />
  );
};

export default NewEvent;
