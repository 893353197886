import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';
import isMobileDevice from 'utils/mobileAndTabletCheck';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import moment from 'moment';
import formattedDate from 'utils/formattedDate';

const Date = ({start_date, end_date, grid}) => {

    const fullStartDate = formattedDate(start_date);
    const fullEndDate = formattedDate(end_date);
    const endDateTime = formattedDate(end_date, "time");
    const isSameDate = moment(start_date).diff(moment(end_date), 'days') === 0;
    const isMobile = isMobileDevice();

    const shownDate = end_date
        ? isSameDate
            ? `${fullStartDate} - ${endDateTime}`
            : isMobile || grid
                ? `${fullStartDate}`
                : `${fullStartDate} - ${fullEndDate}`
        : fullStartDate

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                <EventAvailableIcon color="warning" fontSize='14px'/>
                <Description small>{shownDate}</Description>
            </div>

            {(end_date && !isSameDate && (isMobile || grid)) &&
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <EventBusyIcon color="warning" fontSize='14px'/>
                    <Description small>{fullEndDate}</Description>
                </div>
            }
        </div>
    );
};

Date.propTypes = {
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    grid: PropTypes.bool
};

export default Date;
