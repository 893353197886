import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';
import Locked from './Locked';
import Unlocked from './Unlocked';

const ListItem = ({pageElement, handleDeleteRequest, handleUpdateRequest, organizationId}) => {
  
  const profileClass = pageElement.element_type.includes("_embed") && !organizationId && !pageElement.locked ?  "profile" : "";

  return (
    <div className={`ui-page-element ${pageElement.locked ? "" : pageElement.element_type} ${profileClass}`}>
        {pageElement.locked 
          ? <Locked pageElement={pageElement} organizationId={organizationId} />
            : <Unlocked pageElement={pageElement} handleDeleteRequest={handleDeleteRequest} handleUpdateRequest={handleUpdateRequest}/>
        }
    </div>
  );
};

ListItem.propTypes = {
    pageElement: PropTypes.object,
    handleDeleteRequest: PropTypes.func,
    handleUpdateRequest: PropTypes.func,
    organizationId: PropTypes.number
};

export default ListItem;