import React, { useState } from 'react';
import { useController } from '@rest-hooks/react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import AuthenticationResource from 'resources/auth/AuthenticationResource';

import loginValidation from './Validations/loginForm';

import { StyledTextField } from 'components/StyledTextField';

import Button from 'ui/Button';
import FormSection from 'ui/FormSection';
import Label from 'ui/text/Label';

import AuthToken from 'utils/localStorage/AuthToken';
import CoursesList from 'utils/localStorage/CoursesList';
import UserName from 'utils/localStorage/UserName';
import { useError } from 'utils/useErrorController';
import redirectToView from 'utils/redirectToView';

import { t } from 'i18n/index';
import { Link } from '@mui/material';

const LogInForm = ({done, onLoginChange=null}) => {
  
  const { fetch } = useController();
  const history = useHistory();
  const {loading, setLoading, validate, handleError} = useError();
  
  const [data, setData] = useState({});

  const handleChange = ({ target: {name, value}}) => {
    setData( {
      ...data,
      [name]: value
    });
  };

  const handleEmailLogin = async () => {
    try {
      validate(data, loginValidation);

      const formData = new FormData();
      Object.entries(data).forEach(([name, value]) => formData.append(name, value));

      await handleLogin(data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleLogin = async (data) => {
    const { auth_token, user, courses } = await fetch(AuthenticationResource.create(), data );

    if (auth_token){
      AuthToken.write(auth_token);
      const { role, name } = user;
      CoursesList.write(courses);
      UserName.write(name);

      if (done){
        done()
      } else {
        redirectToView(history, role, courses);
      }
      setLoading(false);
    }
  };

  return (
    <FormSection slim >
      <StyledTextField
        label={t('literal:email')}
        name="email"
        value={data.email || ''}
        onChange={ handleChange }
        fullWidth>
      </StyledTextField>

      <StyledTextField
        label={t('literal:password')}
        name="password"
        type={'password'}
        value={data.password || ''}
        onChange={ handleChange }
        fullWidth>
      </StyledTextField>

      <Label secondary padded>
        {t('authForms:loginForm:forgotPasswordQuestion')}&nbsp;
        <Link href="/password_resets" target="_blank">
          {t('authForms:loginForm:forgotPasswordLink')}
        </Link><br/>
          {onLoginChange &&
            <>
            {t('authForms:loginForm:newToMembrzQuestion')}
            <Link component="button" onClick={ onLoginChange }>
              {t('authForms:loginForm:signUpCall')}
            </Link>
          </>
          }
      </Label>

      <Button fullwidth loading={loading} onClick={ handleEmailLogin }>
        {t('literal:signIn')}
      </Button>
    </FormSection> 
  );
};

LogInForm.propTypes = {
  done: PropTypes.func,
  showSignUp: PropTypes.bool,
  onLoginChange: PropTypes.func
};

export default LogInForm;
