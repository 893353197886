import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import ProductInstanceResource from 'resources/organization/ProductInstanceResource';
import ProductInstanceDownloadResource from 'resources/organization/ProductInstanceDownloadResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import SearchableFilter from 'ui/SearchableFilter';

import { downloadCSV } from 'utils/downloadFile';
import { offerInstancesColumns } from 'utils/tables/columns/offerInstancesColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import { useError } from 'utils/useErrorController';

const OfferInstancesTabContent = ({organizationId}) => {

const { fetch } = useController();
const { handleError } = useError();

const {
  objectState,
  fetchData,
  handleFilterChange,
  handleQueryChange,
  handlePageChange
} = usePaginationAndFilteringController({
    fetchDataResource: ProductInstanceResource,
    fetchDataParams: { organization_id: organizationId, products: true },
})

useEffect(() => {
  fetchData();
}, []);

const handleDownload = async () => {
  try {
    const response = await fetch(ProductInstanceDownloadResource.list(), {
      organization_id: organizationId,
      products: true,
      ...objectState.filteringParams,
    });
    const isSL = organizationId === 21;
    const csvTitle = isSL ? 'sl_products.csv' : 'products.csv';
    downloadCSV(response, csvTitle, 'text/csv');
  } catch (error) {
    handleError(error.message);
  }
};

return (
  <div style={{ flexGrow: 1 }}>
    <DataSelectionTable records={objectState.filteredObjects}
                        columns={offerInstancesColumns}
                        onClick={handleDownload}
                        onQueryChange={handleQueryChange}
                        queryString={objectState.queryString}
                        searchLabel="Search by Ref, Order or Member"
                        serverPaginationProps={{
                          paginationServer: true,
                          paginationTotalRows: objectState.totalCount,
                          page: objectState.page,
                          onChangePage: handlePageChange
                        }}
                        filter={
                          <div className='filter-group'>
                            <SearchableFilter
                              label="product"
                              onChange={handleFilterChange}
                            />
                          </div>
                          }
    />
  </div>
)};

OfferInstancesTabContent.propTypes = {
  handleError: PropTypes.func,
  organizationId: PropTypes.number,
};

export default OfferInstancesTabContent;
