import React from "react";
import PropTypes from "prop-types";

import CloseButton from "./CloseButton";
import SimpleSlider from "./SimpleSlider";
import SingleImage from "./SingleImage";

import { Backdrop } from "@mui/material";

import "./styles.css";

const Slider = ({ open, handleClose, image_urls, selectedIndex }) => {

  return (
    <Backdrop open={open} sx={{ zIndex: 1500 }} className="ui-slider">
      
      <CloseButton handleClose={handleClose} />

      {image_urls.length > 1 ?
        <SimpleSlider image_urls={image_urls} selectedIndex={selectedIndex} handleClose={handleClose}/>
        : 
        <SingleImage imageUrl={image_urls[0]} />
      }
    </Backdrop>
  );
};

Slider.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  image_urls: PropTypes.array,
  selectedIndex: PropTypes.number,
};

export default Slider;
