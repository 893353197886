import React from "react";
import PropTypes from 'prop-types';

import Details from "./Details";
import ActionButtons from "./ActionButtons";
import Media from "./Media";
import Publishing from "./Publishing";
import Tickets from "./Tickets";

import SeoOptions from "components/SeoOptions";

const EventForm = ({event, setEvent, onSubmit, loading, onChange, onError, unsavedChanges, setUnsavedChanges}) => {

    return (
        <form>
            <Details
                event={event}
                setEvent={setEvent}
                onChange={onChange}
                setUnsavedChanges={setUnsavedChanges} />

            <Publishing
                event={event}
                onEventChange={setEvent}
                onChange={onChange}
                setUnsavedChanges={setUnsavedChanges} />

            <Tickets
                showTicketing={event.ticketing}
                tickets={event.tickets}
                unsavedChanges={unsavedChanges}
                setUnsavedChanges={setUnsavedChanges}
                />

            <Media
                object={event}
                onObjectChange={setEvent}
                onError={onError}
                setUnsavedChanges={setUnsavedChanges} />

            <SeoOptions
                slug={event.slug}
                onChange={onChange}
                showShortDescription={true}
                short_description={event.short_description} />

            <ActionButtons
                status={event?.status}
                loading={loading}
                onSubmit={onSubmit} />
        </form>
    );
};

EventForm.propTypes = {
    event: PropTypes.object,
    setEvent: PropTypes.func,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onError:PropTypes.func,
    unsavedChanges: PropTypes.bool,
    setUnsavedChanges: PropTypes.func
};

export default EventForm;
