import React from "react";
import PropTypes from "prop-types";

import PriceField from "./PriceField";

import Label from "ui/text/Label";

import { currencies } from "utils/pricingsConsts";

const PricingAndApplicationFee = ({defaultFrequency, price, currency, isDisabled, errors, onPriceChange, onChange, applicationFee, showApplicationFee}) => {

  const totalPrice = +price + +applicationFee || 0;
  const applicationFeeWithCurrency = `${applicationFee || 0} ${currencies[currency].symbol}`;
  const totalPriceWithCurrency = `${totalPrice} ${currencies[currency].symbol}`;

  return (
    <>
        <PriceField
          frequency={ defaultFrequency }
          price={ price }
          isDisabled={ isDisabled }
          errors={errors}
          onPriceChange={onPriceChange}
          onChange={onChange}
          icon={currencies[currency].icon}
          showApplicationFee={showApplicationFee}
          />

        { showApplicationFee && !isDisabled &&
          <Label secondary padded>
            An aplication fee of {applicationFeeWithCurrency} will be added to the product price, and includes all payment processing and infrastructure costs.<br/>
            The final price, including the fee, will be {totalPriceWithCurrency}.
          </Label>
        }
    </>
  );
};

PricingAndApplicationFee.propTypes = {
  defaultFrequency: PropTypes.string,
  price: PropTypes.object,
  currency: PropTypes.string,
  isDisabled: PropTypes.bool,
  errors: PropTypes.object,
  onPriceChange: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
  applicationFee: PropTypes.number,
  showApplicationFee: PropTypes.bool,
};

export default PricingAndApplicationFee;
