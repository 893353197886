import React, { useEffect, useState } from "react";
import { useController, useSuspense } from "@rest-hooks/react";
import { useHistory, useParams } from "react-router-dom";

import ProductResource from "resources/organization/ProductResource";
import OrganizationResource from "resources/organization/OrganizationResource";
import TagResource from 'resources/organization/TagResource';

import Layout from "components/layouts";
import TabPanels from "components/TabPanels";
import DataSelectionTable from "components/DataTable/DataSelectionTable";
import ConfirmModal from "components/ConfirmModal";
import DragDropCtx from "components/DragDropCtx";
import DraggableList from "components/DragDropCtx/DraggableList";

import ListItem from "./ListItem";

import Button from "ui/Button";

import { finalizedOffersColumns } from "utils/tables/columns/finalizedOffersColumns";
import { updateDraggableList } from "utils/updateDraggableList";
import { useError } from "utils/useErrorController";
import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import { scrollToTop } from "utils/scrollToTop";
import usePaginationAndFilteringController from "utils/usePaginationAndFilteringController";
import copyToClipboard from 'utils/copyToClipboard';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import StripeConnectAlert from "components/StripeConnectAlert";

const Offers = () => {
  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const activeOffers = useSuspense(ProductResource.list(), { organization_id: organizationId, active: true });
  const organization = useSuspense(OrganizationResource.detail(), {id: organizationId});

  const {
    objectState,
    fetchData,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: ProductResource,
      fetchDataParams: { organization_id: organizationId },
  })

  useEffect(() => {
    fetchData();
  }, []);

  const {stripe_charges_enabled, eupago_charges_enabled} = organization;
  const paidOffers = activeOffers.filter(offer => offer.price > 0);
  const showAlert = (!stripe_charges_enabled && !eupago_charges_enabled) && paidOffers.length > 0;

  const {  handleError, loading } = useError();

  const { fetch } = useController();
  const history = useHistory();
  const { setOpen, setMessage, setSeverity } = useToast();
  
  const handleDragEnd = async (result) => {
    try {
      updateDraggableList(offers, result.source.index, result.destination.index, setOffers);
      await fetch(ProductResource.update(), { organization_id: organizationId, id: result.draggableId }, { position: result.destination.index });
    } catch (error) {
      const savedList = await fetch(ProductResource.list(), { organization_id: organizationId, active: true});
      setOffers(savedList);
      handleError(error);
    }
  };

  const handleDeleteRequest = (id, title) => {
    setDeletingOffer({ id: id, title: title });
  };

  const handleDelete = async (savedOffers) => {
    try {
      setOffers(createOfferState(savedOffers, true));
      await fetch(ProductResource.delete(), { id: deletingOffer.id, organization_id: organizationId });
      const activeOffers = await fetch(ProductResource.list(), { organization_id: organizationId, active: true });
      setOffers(createOfferState(activeOffers));
      setDeletingOffer(null);
      customToast('success', `Product Deleted`, setOpen, setSeverity, setMessage);
    } catch (error) {
      handleError(error);
    }
  };

  const handleUrlCopy = (external_url) => {
    copyToClipboard(external_url, setOpen, setSeverity, setMessage)
  };

  const handlePreview = (external_url) => {
    window.open(external_url, '_blank');
  };

  const organizationTags = useSuspense(TagResource.list(), { organization_id: organizationId, tag_type: 'product' });

  const createOfferState = (offers, loading = false) => {
    return offers.map((offer) => ({
      id: offer.id,
      component: <ListItem 
                    handleDeleteRequest={handleDeleteRequest} 
                    handleUrlCopy={handleUrlCopy}
                    handlePreview={handlePreview}
                    loading={loading}
                    organizationTags={organizationTags}
                    offer={offer} />,
    }));
  };

  const handleCreateProductRequest = () => {
    scrollToTop();
    history.push(`/organizations/${organizationId}/perks/new`);
  };

  const [offers, setOffers] = useState(createOfferState(activeOffers));
  const [deletingOffer, setDeletingOffer] = useState(false);

  const pageInfo = {
    name: "Store",
    description: "Create exclusive products for your members",
  };

  const hiddenProductsCount = objectState.filteredObjects.length > 0 ? objectState.filteredObjects[0].total_count : 0;
  const labels = [
    `Shown Products (${offers.length})`,
    `Hidden Products (${hiddenProductsCount})`,
  ];

  return (
    <Layout
      context="teacher"
      activeMenuItem="perks"
      pageInfo={pageInfo}
    >

      <TabPanels labels={labels}>
        <div key={0} className="org-products-list">
          {showAlert &&
            <StripeConnectAlert organizationId={organizationId} />
          }
          <Button icon={AddCircleOutlineIcon} highlighted fullwidth onClick={handleCreateProductRequest}>
            Add Product
          </Button>
          <DragDropCtx handleDragEnd={handleDragEnd}>
            <DraggableList data={offers}
                           droppableId="perks" />
          </DragDropCtx>
        </div>
        <div key={1}>
          <DataSelectionTable
            records={objectState.filteredObjects}
            columns={finalizedOffersColumns(handleDeleteRequest, handleUrlCopy, handlePreview, loading, organizationTags)}
            serverPaginationProps={{
              paginationServer: true,
              paginationTotalRows: objectState.totalCount,
              page: objectState.page,
              onChangePage: handlePageChange
            }}
            subHeader={false}
          />
        </div>
      </TabPanels>

      <ConfirmModal
        title={deletingOffer ? `Delete ${deletingOffer.title}?` : ''}
        open={deletingOffer}
        onConfirm={() => handleDelete(activeOffers)}
        setOpen={setDeletingOffer}
      >
        Are you sure you want to delete this product?
      </ConfirmModal>
    </Layout>
  );
};

export default Offers;
