import React from 'react';
import PropTypes from 'prop-types';

const SingleImage = ({ imageUrl }) => {
  return (
    <div className="single-image-backdrop">
        <img src={imageUrl} />
    </div>
  );
};

SingleImage.propTypes = {
  imageUrl: PropTypes.string
};

export default SingleImage;