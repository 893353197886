import React from 'react';
import PropTypes from 'prop-types';

import Criteria from './Criteria';
import Motive from './Motive';

import Description from 'ui/text/Description';

import { t } from "i18n/index";

const Content = ({product, userPoints, onRedeemMotiveChange, eventTitle}) => {

  const {title} = product;
 
  return (
    <div className='redeem-modal-description'>
      <Description>
        {eventTitle 
         ? t('profile:perks:redeemModal:rsvpStatementOfAction', {eventTitle})
         : t('profile:perks:redeemModal:statementOfAction', {title})}
      </Description>

      <Criteria product={product} userPoints={userPoints} />
      
      {onRedeemMotiveChange && 
        <Motive onRedeemMotiveChange={onRedeemMotiveChange} /> }

      <Description>
        {t('profile:perks:redeemModal:confirmationMessage')}
      </Description>
    </div>
  );
};

Content.propTypes = {
  product: PropTypes.object,
  userPoints: PropTypes.number,
  onRedeemMotiveChange: PropTypes.func,
  eventTitle: PropTypes.string
};

export default Content;