import React from 'react';
import PropTypes from 'prop-types';

import { Draggable } from "react-beautiful-dnd";

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { capitalize } from 'utils/capitalize';
import { profileTabLabels } from 'utils/profileTabLabels';

const DraggableItem = ({tab, index}) => {

  return (
    <Draggable key={tab} draggableId={tab} index={index}>
        {(provided, snapshot) => (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <div className={
              `draggable-item-content ${snapshot.isDragging ? "dragging" : ""}`
            }>
              {profileTabLabels[tab] || capitalize(tab)}
              <DragIndicatorIcon fontSize='small' />
            </div>
        </div>
        )}
    </Draggable>
  );
};

DraggableItem.propTypes = {
  tab: PropTypes.string,
  index: PropTypes.number
};

export default DraggableItem;