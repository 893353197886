import React from 'react';
import PropTypes from 'prop-types';

import Subtitle from "ui/text/Subtitle";
import Label from 'ui/text/Label';

import Calendar from './Calendar';
import Location from './Location';
import TicketCard from './TicketCard';

import { getServiceFeeLabel } from 'utils/profile/getServiceFeeLabel';
import useSliderController from 'utils/useSliderController';

const Header = ({image_url, title, start_date, end_date, location, tickets, onAction, showApplicationFee}) => {

  const serviceFeesLabel = getServiceFeeLabel(showApplicationFee);
  const showApplicationFeeLabel = tickets.find((ticket) => ticket.price > 0);

  const { handleImageClick, SliderComponent} = useSliderController([image_url]);

  return (
    <div className="event-header">

      <div className='sub-header'>
        
        <img src={image_url} alt={title} onClick={handleImageClick} />
      
        <div>
          <Subtitle>{title}</Subtitle>
        
          <Calendar start_date={start_date} end_date={end_date} />

          {location && <Location location={location} />}
        </div>
      </div>
      <div className="tickets">
        {tickets.map((ticket) => (
          <TicketCard key={ticket.id} ticket={ticket} onAction={onAction} />
        ))}
        {showApplicationFeeLabel && <Label padded secondary small className={'application-fee-disclaimer'}>{serviceFeesLabel}</Label>}
      </div>

      <SliderComponent />
    </div>
  );
};

Header.propTypes = {
    image_url: PropTypes.string,
    title: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    location: PropTypes.string,
    tickets: PropTypes.array,
    onAction: PropTypes.func,
    showApplicationFee: PropTypes.bool
};

export default Header;