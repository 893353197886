import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import { capitalize } from 'utils/capitalize';
import { frequencies } from 'utils/pricingsConsts';

import { t } from "i18n/index";

const FrequencySwitch = ({tiers, activeFrequency, onFrequencyChange}) => {

  const foundFrequencies = frequencies.filter(frequency =>
    tiers.some(pass =>
      pass.pricings && pass.pricings.some(pricing =>
        pricing.frequency === frequency
      )
    )
  );

  if (foundFrequencies.length <= 1) return null 

  const gridTemplateColumns = `repeat(${foundFrequencies.length}, 1fr)`;

  return (
    <div className='frequency-switch' style={{gridTemplateColumns: gridTemplateColumns}}>
      {foundFrequencies.map((frequency) => (
        <Button key={frequency} 
                onClick={() => onFrequencyChange(frequency)}
                text={frequency !== activeFrequency}
                highlighted={frequency === activeFrequency} 
                rounded
                fullwidth >
                {capitalize(t(`literal:${frequency}`))}
        </Button>
      ))}
    </div>
  );
};

FrequencySwitch.propTypes = {
    tiers: PropTypes.array,
    activeFrequency: PropTypes.string,
    onFrequencyChange: PropTypes.func
};

export default FrequencySwitch;