import React from 'react';
import PropTypes from 'prop-types';

import SearchableFilter from 'ui/SearchableFilter';

const TicketsFilter = ({ onChange }) => {

  return (
    <div className='filter-group'>
        <SearchableFilter
          label="ticket"
          onChange={onChange}
        />
        <SearchableFilter
          label="event"
          onChange={onChange}
        />
    </div>
  );
};

TicketsFilter.propTypes = {
  onChange: PropTypes.func,
};

export default TicketsFilter;
