import React, { useState } from "react";
import Slider from "ui/Slider";

const useSliderController = (image_urls) => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
  
    const handleImageClick = (index) => {
      setSelectedIndex(index);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const SliderComponent = () => (
      open && (
        <Slider 
          open={open} 
          handleClose={handleClose} 
          image_urls={image_urls} 
          selectedIndex={selectedIndex} 
        />
      )
    );
  
    return {
      handleImageClick,
      SliderComponent
    };
  };
  
  export default useSliderController;