import React from 'react';
import PropTypes from 'prop-types';
import EmailReviewContent from './EmailReviewContent';

const EmailConfirmModalContent = ({content, subject, userCount=null, ticketCount=null}) => {

  const count = userCount || ticketCount;
  const suffix = count > 1 ? 's' : '';
  const context = userCount
                    ? `${count} user${suffix}.`
                    : `the holders of ${count} ticket${suffix}.`;
                    
  return (
    <>
      <p>You are about to send the following email to {context} Would you like to continue?</p>
      <EmailReviewContent content={content} subject={subject} />
    </>
  );
};

EmailConfirmModalContent.propTypes = {
  content: PropTypes.string,
  subject: PropTypes.string,
  userCount: PropTypes.number,
  ticketCount: PropTypes.number
};

export default EmailConfirmModalContent;