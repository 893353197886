import React from 'react';
import PropTypes from 'prop-types';

import NotFound from './NotFound';
import Internal from './Internal';

const fallbackComponents = {
  404: NotFound,
  500: Internal,
};

const NetworkError = ({error}) => {
  if (error.status === 401) window.location.href = "/";

  const FallbackComponent = fallbackComponents[error.status];
  return (
    <FallbackComponent error={error} />
  );
};

NetworkError.propTypes = {
    error: PropTypes.object,
};

export default NetworkError;