import React from 'react';
import PropTypes from 'prop-types';
import Label from 'ui/text/Label';

const OrderCardImage = ({qr_code_url, reference}) => {
  return (
    <div className={"fullwidth-card-image-container"}>
        <img
            src={qr_code_url}
            className={`hidden fullwidth-card-image`}
          />
        <Label>{reference}</Label>
      </div>
  );
};

OrderCardImage.propTypes = {
    qr_code_url: PropTypes.string,
    reference: PropTypes.string
};

export default OrderCardImage;