import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Title = ({children}) => {
  return (
    <h1 className='ui-title'>{children}</h1>
  )
};

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;