import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Slider from "react-slick";

import 'assets/css/slick.css';

const SimpleSlider = ({ image_urls, selectedIndex, handleClose }) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: selectedIndex,
        lazyLoad: true,
      };

      const handleKeyDown = (e) => {
        if (e.key === 'ArrowLeft' || e.key === 'ArrowUp' || e.key === 'Shift') {
          e.preventDefault();
          simulateButtonClick("prev");
        } else if (e.key === 'ArrowRight' || e.key === 'ArrowDown' || e.key === 'Tab') {
          e.preventDefault();
          simulateButtonClick("next");
        } else if (e.key === 'Escape') {
          handleClose();
        }
      };

      const simulateButtonClick = (direction) => {
        const button = document.querySelector(`.slick-arrow.slick-${direction}`);
        if (button) button.click();
      };

      useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

  return (
    <Slider {...settings} style={{ width: "85vw", zIndex: 1501 }}>
        {image_urls.map((imageUrl, index) => (
            <img key={index} src={imageUrl} />
        ))}
    </Slider>
  );
};

SimpleSlider.propTypes = {
    image_urls: PropTypes.array,
    selectedIndex: PropTypes.number,
    handleClose: PropTypes.func
};

export default SimpleSlider;
