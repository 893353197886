import React from "react";
import PropTypes from "prop-types";

import { TextField } from "@mui/material";

const SupplyLimit = ({supply_limit, onChange}) => {

  const label = "Total stock. Leave empty for unlimited"
                      
  return (
      <TextField
        fullWidth
        size="small"
        color="warning"
        name="supply_limit"
        type="number"
        value={supply_limit}
        onChange={onChange}
        label={label}
        inputProps={{ inputMode: 'numeric'}}
        />
  );
};

SupplyLimit.propTypes = {
    supply_limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
};

export default SupplyLimit;