import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import TooltipComponent from './Tooltip';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

import { t } from "i18n/index";

const getDiscountIcon = (discount_percentage) => {
  if (discount_percentage) {
    return <TooltipComponent message={t('profile:perks:discountPerkMessage', {discount_percentage})} Icon={LoyaltyIcon}/>
  }
  return <LoyaltyIcon color='disabled'/>
}

const BuyButton = ({onAction, loading, disableBuyButton, priceWithCurrency, discount_percentage, available}) => {

  const buttonIcon = getDiscountIcon(discount_percentage);

  return (
    <Button onClick={ onAction } secondary fullwidth loading={loading} disabled={disableBuyButton}>
        {available ? 
        <div className='button-content'>
          <span>{priceWithCurrency}</span>
          <span>{t('literal:buyNow')}</span>
          {buttonIcon}
        </div>
        :
          <span>{t('literal:soldOut')}</span>
        }
    </Button>
  );
};

BuyButton.propTypes = {
  onAction: PropTypes.func,
  loading: PropTypes.bool,
  disableBuyButton: PropTypes.bool,
  priceWithCurrency: PropTypes.string,
  discount_percentage: PropTypes.string,
  available: PropTypes.bool
};

export default BuyButton;
