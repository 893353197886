import React from 'react';
import PropTypes from 'prop-types';

import Subtitle from 'ui/text/Subtitle';
import Description from 'ui/text/Description';
import { Divider } from '@mui/material';

const Header = ({ event_title, title, description }) => {

  return (
    <div className='fullwidth-card-header'>
        <Subtitle>{event_title}</Subtitle>
        <Description>{title}</Description>

        <Divider />

        <Description>{description}</Description>
    </div>
  );
};

Header.propTypes = {
    event_title: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default Header;