import { capitalize } from "./capitalize";

export const signUpCustomFields = {
  'asa-perafita': ['matricula'],
  'unicornfactorylisboa': ['startup']
};

export const checkCustomFields = (organizationSlug) => {
  return Object.keys(signUpCustomFields).includes(organizationSlug)
}

export const transformCustomField = (customField) => {
  return capitalize(customField.replace(/_/g, ' '));
}