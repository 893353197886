import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useController } from "@rest-hooks/react";

import RegistrationResource from "resources/auth/RegistrationResource";
import ProfileResource from "resources/Profile/ProfileResource";
import AuthenticationResource from "resources/auth/AuthenticationResource";
import MembershipResource from "resources/Profile/MembershipResource";
import ClaimResource from "resources/public/ClaimResource";
import PasswordResetResource from "resources/user/PasswordResetResource";

import { validateEmail } from "utils/validations/validateEmail";
import { useError } from "utils/useErrorController";
import CoursesList from "utils/localStorage/CoursesList";
import UserName from "utils/localStorage/UserName";
import AuthToken from "utils/localStorage/AuthToken";

import { t } from "i18n/index";

import { StyledTextField } from "components/StyledTextField";

import Button from "ui/Button";
import Label from "ui/text/Label";

import { Alert, CircularProgress } from "@mui/material";

const handleSuccessfulAuth = (auth_token, user, courses = []) => {
  AuthToken.write(auth_token);
  CoursesList.write(courses);
  UserName.write(user.name);
};

const ExpressForm = ({ freePassClaimToken, organizationSlug, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [step, setStep] = useState(1);

  const { handleError, loading, setLoading } = useError();

  const { fetch } = useController();

  const handleClick = () => {
    step === 1 ? handleSignUp() : handleSignIn();
  };

  const handleSignUp = async () => {
    try {
      setLoading(true);

      validateEmail(email, null, t("errors:emailMissing"));

      const formData = new FormData();
      formData.append('email', email);

      const { auth_token, user } = await fetch(RegistrationResource.create(), formData);
      handleSuccessfulAuth(auth_token, user);

      refreshCachedData();
      setStep(3);
      setLoading(false);

    } catch (error) {
      if (typeof error === "string") {
        handleError(error);

      } else {
        const errorResponse = await error.response.json();
        const firstErrorMessage = errorResponse?.errors?.user?.[0];
        const userExists = firstErrorMessage && firstErrorMessage.includes("already exists.");
  
        if (userExists) {
          setStep(2);
          setLoading(false);
        } else {
          handleError(error);
        }
      }
    }
  };

  const handleSignIn = async () => {
    try {
      setLoading(true);
      const { auth_token, user, courses } = await fetch(AuthenticationResource.create(), { email: email, password: password }
      );
      handleSuccessfulAuth(auth_token, user, courses);

      const response = await fetch(MembershipResource.detail(), { organization_slug: organizationSlug, id: freePassClaimToken });
      const { already_issued_to, is_downgrading } = response;

      if (already_issued_to) {
        refreshCachedData();
        handleError(t("errors:passAlreadyIssued"));
      } else if (is_downgrading) {
        refreshCachedData();
        handleError(t("profile:freeSubscriptionModal:downgradingToFree"));
      } else {
        handleClaim();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleClaim = async () => {
    try {
      await fetch(ClaimResource.create(), { id: freePassClaimToken });
      refreshCachedData();
      setStep(3);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const refreshCachedData = async () => {
    await fetch(MembershipResource.list(), { organization_slug: organizationSlug });
    await fetch(ProfileResource.detail(), { id: organizationSlug });

    const inContentPage = window.location.pathname.includes("/content") || window.location.pathname.includes("/tags");
    if (inContentPage) window.location.reload();
  };

  const handleChange = ({ target: { name, value } }) => {
    const stateSetters = {
      email: setEmail,
      password: setPassword,
    };

    stateSetters[name](value);
  };

  const handlePasswordResetRequest = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await fetch(PasswordResetResource.create(), { email: email });
      setStep(5);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const authToken = AuthToken.read();
    const isLoggedIn = !!authToken;

    if (isLoggedIn) {
      setStep(4);
      handleClaim();
    }
  }, []);

  const renderTextField = (placeholder, name, value, inputProps) => {
    return (
      <StyledTextField
        className="textfield"
        placeholder={placeholder}
        name={name}
        type={name}
        value={value}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleClick();
        }}
        InputProps={inputProps}
      />
    );
  };

  const renderAlert = (severity, text, action) => {
    return (
      <Alert
        severity={severity}
        className="alert"
        action={action}
        sx={{ alignItems: "center" }}
      >
        {text}
      </Alert>
    );
  };

  return (
    <>
      {step < 3 &&
        renderTextField(
          t("profile:freeSubscriptionModal:emailPrompt"),
          "email",
          email,
          step === 1 && {
            endAdornment: (
              <Button highlighted onClick={handleClick} loading={loading}>
                {t("literal:subscribe")}
              </Button>
            ),
          }
        )}

      {step === 2 && (
        <>
          {renderTextField(
            t("profile:freeSubscriptionModal:passwordPrompt"),
            "password",
            password,
            {
              endAdornment: (
                <Button highlighted onClick={handleClick} loading={loading}>
                  {t("literal:submit")}
                </Button>
              ),
            }
          )}
          <Button
            text
            small
            onClick={(e) => handlePasswordResetRequest(e)}
          >
            {`${t("account:common:forgotPasswordAndResetPasswordPageTitle")} ${t("account:forgotPassword:requestInstructions")}`}
          </Button>
        </>
      )}

      {step === 3 &&
        renderAlert(
          "success",
          `${t("literal:welcome")}!`,
          <Button secondary onClick={onClose} loading={loading}>
            {t("literal:goTo")}
          </Button>
        )}

      {step === 4 &&
        renderAlert(
          "info",
          t("literal:claiming"),
          <CircularProgress size={20} />
        )}

      {step === 5 && (
        <Label secondary bold>{`${t("account:forgotPassword:resetInstructions")}`}</Label>
      )}
    </>
  );
};

ExpressForm.propTypes = {
  freePassClaimToken: PropTypes.string,
  organizationSlug: PropTypes.string,
  onClose: PropTypes.func,
};

export default ExpressForm;
