import { useState } from 'react';
import { useController } from '@rest-hooks/react';

import ProductResource from 'resources/Profile/ProductResource';
import MembershipResource from 'resources/Profile/MembershipResource';
import TicketResource from 'resources/Profile/TicketResource';

import AuthToken from './localStorage/AuthToken';
import { useError } from './useErrorController';
import { offerCategory } from './offersFormHelper';

import { t } from "i18n/index";

const defaultModalState = {
  authModal: false,
  redeemModal: false,
  checkoutModal: false,
};

export const usePreActionController = (organizationSlug, activeTag=null, eventSlug=null) => {
  
  const [currentAction, setCurrentAction] = useState(null);
  const [modalsState, setModalsState] = useState(defaultModalState)

  const { fetch } = useController();
  const { handleError } = useError();

  const handleAction = (product, redemptionMethod) => {
    setCurrentAction({ product, redemptionMethod });
    const authToken = AuthToken.read();
    if (authToken) {
      if (redemptionMethod === 'points') {
        // check if enough points to redirect to memberships and tranform into subscriptions quicker (not to validate)
        setModalsState({ ...modalsState, redeemModal: true });
      } else {
        setModalsState({ ...modalsState, checkoutModal: true });
      }
    } else {
      setModalsState({ ...modalsState, authModal: true });
    }
  };

  const handleSuccessfulAuth = async () => {
    //update perks with redeemed_counts after auth
    let products
    const {id, category} = currentAction.product;
    if (category === offerCategory.ticket.value) {
      const event = await fetch(TicketResource.detail(), {organization_slug: organizationSlug, event_slug: eventSlug, id: id });
      products = event?.tickets;
    } else {
      products = await fetch(ProductResource.list(), {organization_slug: organizationSlug, tag_id: activeTag?.id});
    }

    //check if already redeemed as redeem_limit is not yet in the redeem modal criteria and button wasnt disabled because user was logged out
    const product = products.find(product => product.id === id);
    const alreadyRedeemedMax = product.redeem_limit && product.redeemed_count >= product.redeem_limit;

    //refresh memberships list
    await fetch(MembershipResource.list(), {organization_slug: organizationSlug});

    if (alreadyRedeemedMax) {
      handleError(t('errors:redeemedMax'));

    } else if (currentAction.redemptionMethod === 'points') {
      //update user_points after auth

      //check if enough points to redirect to memberships and tranform into subscriptions quicker (not to validate)
      setModalsState({ ...modalsState, redeemModal: true, authModal: false});

    } else {
      setModalsState({ ...modalsState, checkoutModal: true, authModal: false });
    }
  };

  const handleClose = () => {
    setCurrentAction(null);
    setModalsState(defaultModalState);
  };

  return {  
    currentAction,
    modalsState,
    onAction: handleAction,  
    onSuccessfulAuth: handleSuccessfulAuth,
    onClose: handleClose,
  };
};
