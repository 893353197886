import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';

import ProductInstanceResource from 'resources/organization/ProductInstanceResource';
import ProductInstanceDownloadResource from 'resources/organization/ProductInstanceDownloadResource';
import EventResource from 'resources/organization/EventResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import TicketsFilter from './TicketsFilter';
import Stats from './Stats';

import { downloadCSV } from 'utils/downloadFile';
import { soldTicketsColumns } from 'utils/tables/columns/soldTicketsColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import { useError } from 'utils/useErrorController';

const TicketsTabContent = ({organizationId}) => {

const { fetch } = useController();
const { handleError } = useError();

const events = useSuspense(EventResource.list(), {organization_id: organizationId});

const {
  objectState, 
  fetchData,
  handleFilterChange,
  handleQueryChange,
  handlePageChange
} = usePaginationAndFilteringController({
    fetchDataResource: ProductInstanceResource,
    fetchDataParams: { organization_id: organizationId },
})
    
useEffect(() => {
  fetchData();
}, []);

const handleDownload = async () => {
  try {
    const response = await fetch(ProductInstanceDownloadResource.list(), {
      organization_id: organizationId,
      ...objectState.filteringParams,
    });
    downloadCSV(response, 'tickets.csv', 'text/csv');
  } catch (error) {
    handleError(error.message);
  }
};

return (
  <div style={{ flexGrow: 1 }}>
    <Stats tickets={objectState.filteredObjects}
           events_count={events.length}
    />
    <DataSelectionTable records={objectState.filteredObjects}
                        columns={soldTicketsColumns}
                        onClick={handleDownload}
                        onQueryChange={handleQueryChange}
                        queryString={objectState.queryString}
                        searchLabel="Search by Ref, Order or Member"
                        serverPaginationProps={{
                          paginationServer: true,
                          paginationTotalRows: objectState.totalCount,
                          page: objectState.page,
                          onChangePage: handlePageChange
                        }}
                        filter={
                          <TicketsFilter
                              onChange={handleFilterChange}
                            />
                          }  
    />
  </div>
)};

TicketsTabContent.propTypes = {
  organizationId: PropTypes.number,
};

export default TicketsTabContent;
