import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ThemeResource from 'resources/Profile/ThemeResource';

import About from 'containers/public/Profile/Body/About';

import Button from 'ui/Button';
import Title from 'ui/text/Title';
import Description from 'ui/text/Description';
import ProfileImages from 'ui/ProfileImages';

import isMobileDevice from 'utils/mobileAndTabletCheck';
import { externalUrl } from 'utils/urls';
import { useFreeSubscription } from 'utils/freeSubscriptionController';

import logo from 'assets/images/logo-membrzclub.png'

import { t } from "i18n/index";

import './styles.css';
import { useSuspense } from '@rest-hooks/react';

const Sidebar = ({organization}) => {

  const {user_is_free_member, user_is_member, followers, cover_image, image, title, free_pass_claim_token, has_paid_pass, slug} = organization;

  const theme = useSuspense(ThemeResource.detail(), { id: slug, organization_slug: slug });

  const isDesktop = !isMobileDevice();

  const history = useHistory();
  const handleRedirect = () => {
    history.push(`memberships`);
  };

  const {openFreeSubscriptionModal} = useFreeSubscription();

  const showUpgradeButton = user_is_free_member && has_paid_pass;

  const nonMemberCTA = theme?.theme?.custom_cta || t('profile:sidebar:joinTheClub');
  const buttonLabel = showUpgradeButton ? t('literal:upgradeMembership') : !user_is_member ? nonMemberCTA : null;
  const inMembershipsPage = window.location.pathname.includes("memberships");
  const showJoinTheClubButton = !!buttonLabel && !inMembershipsPage;
  const triggerFreeSubscriptionClaim = free_pass_claim_token && !user_is_member;

  return (
    <div className='sidebar'>

        <>
          <ProfileImages coverImageSrc={cover_image} profileImageSrc={image} />

          <div className='content'>
            <Title>{title}</Title>

            { followers >= 20 &&
              <Description secondary>{followers + " " + t('literal:members_sc')}</Description>
            }

            { showJoinTheClubButton &&
              <Button
                highlighted
                fullwidth
                onClick={triggerFreeSubscriptionClaim
                          ? () => openFreeSubscriptionModal()
                          : handleRedirect} >
               {buttonLabel}
              </Button>
            }

            { isDesktop &&
              <About inSidebar={true}/>
            }

          </div>
        </>

        <div className='logo'>
          <p>Powered By</p>
          <a target='_blank'
             rel='noopener noreferrer'
             href={externalUrl}
             title="Membrz.Club"
            >
            <img src={logo} alt="Membrz.Club"/>
          </a>
        </div>
    </div>
  );
};

Sidebar.propTypes = {
    organization: PropTypes.object,
};

export default Sidebar;
