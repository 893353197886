import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const CheckInModalContent = ({ ticket }) => {
  return (
    <>
      <ul>
        <li>
          Status:
          {ticket.redeem_state === "completed"
            ? " ✅ Checked In"
            : " ❌ Not Checked In"}
        </li>
        {ticket.completed_at && <li>Checked-in on: {moment(ticket.completed_at).format('lll')}</li>}
      </ul>

      <ul>
        <li>Guest: {ticket.user_name}</li>
        <li>Email: {ticket.user_email}</li>
        <li>Order ID: {ticket.order_id ? ticket.order_id : "N/A"}</li>
        <li>Ticket reference: {ticket.uuid}</li>
        <li>Purchased on: {moment(ticket.created_at).format('lll')}</li>
      </ul>

    </>
  );
};

CheckInModalContent.propTypes = {
  ticket: PropTypes.object.isRequired,
};

export default CheckInModalContent;
