import React from 'react';
import PropTypes from 'prop-types';

import DateInputField from 'components/DateInputField';

import FormSection from 'ui/FormSection';

import { slugify } from 'utils/slugify';

import { TextField } from '@mui/material';

const Details = ({event, setEvent, onChange, setUnsavedChanges}) => {

  const {title, location} = event;

  return (
    <FormSection title={"Event Info"}>
        <TextField
            fullWidth
            size='small'
            color='warning'
            name={"title"}
            label={"Title *"}
            value={title}
            onChange={onChange}
            onBlur={() => slugify(event, setEvent)}
        />

        <DateInputField
            attribute={'start_date'}
            object={event}
            onChange={setEvent}
            label={'Start Date *'}
            disablePast={true}
            setUnsavedChanges={setUnsavedChanges}
        />

        <DateInputField
            attribute={'end_date'}
            object={event}
            onChange={setEvent}
            label={'End Date'}
            setUnsavedChanges={setUnsavedChanges}
        />

        <TextField
            fullWidth
            size='small'
            color='warning'
            name={"location"}
            label={"Location"}
            value={location}
            onChange={onChange}
        />
    </FormSection>
  );
};

Details.propTypes = {
    event: PropTypes.object,
    setEvent: PropTypes.func,
    onChange: PropTypes.func,
    setUnsavedChanges: PropTypes.func
};

export default Details;
