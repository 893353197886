import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';
import { useError } from 'utils/useErrorController';

import Button from 'ui/Button';
import TextField from 'components/formElements/TextField';
import IntegrationsResource from 'resources/organization/IntegrationsResource';
import { useParams } from 'react-router-dom';
import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import FormSection from 'ui/FormSection';

const defaultSettings = { url: '', profile_tab: ''};

const Notion = () => {

    const {loading, setLoading, handleError} = useError();
    const { setOpen, setMessage, setSeverity } = useToast();
    const { fetch } = useController();
    const { organizationId } = useParams();

    const notion = useSuspense(IntegrationsResource.detail(), { organization_id: organizationId, id: 'notion' });

    const [notionSettings, setNotionSettings] = useState( notion?.settings || defaultSettings);

    const handleChange = ({target}) => {
      const { value, name } = target;

      setNotionSettings({
        ...notionSettings,
        [name]: value
      })
    }

    const handleSave = async () => {
        try {
            setLoading(true);
            await fetch(IntegrationsResource.update(), { organization_id: organizationId, id: 'notion' }, { settings: notionSettings });
            customToast('success', "Notion page updated", setOpen, setSeverity, setMessage);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            handleError(error);
        }
     };

  return (
    <>
      <FormSection title='Notion'>
        <p>Embed a Notion page inside your Club page with <a href='https://embednotionpage.com'>EmbedNotionPage</a>.</p>
        <p>Follow the instructions there, then paste your generated Embed link here.</p>

        <TextField
            label={'Embed Notion URL'}
            name={'url'}
            value={notionSettings.url}
            onChange={ handleChange }
            fullWidth
            margin="dense"
            color="warning">
        </TextField>

        <TextField
            label={'Club Page Section Name'}
            name={'profile_tab'}
            value={notionSettings.profile_tab}
            onChange={ handleChange }
            fullWidth
            margin="dense"
            color="warning">
        </TextField>
      </FormSection>
      
      <div className='new-form-field-footer'>
        <Button loading={loading} onClick={ handleSave } minwidth>
          Save
        </Button>
      </div>
    </>
    

  );
};

Notion.defaultProps = {
    groupChat: {},
};

Notion.propTypes = {
    organization: PropTypes.object,
    groupChat: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default Notion;
