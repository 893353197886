import React, { useState } from "react";
import PropTypes from "prop-types";

import DraggableList from "./DraggableList";

import { useError } from "utils/useErrorController";

import Label from "ui/text/Label";
import FormSection from "ui/FormSection";

import { DragDropContext } from "react-beautiful-dnd";

import { Alert } from "@mui/material";

import './styles.css';

const TabsOrganizer = ({ profile_tabs, onChange, onUnsavedChanges }) => {

  const possibleTabs = ["about", "memberships", "store", "content", "events"];
  const [removedTabs, setRemovedTabs] = useState(
    possibleTabs.filter((item) => !profile_tabs.includes(item))
  );

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const { handleError } = useError();

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    onUnsavedChanges(true);

    const sourceList = result.source.droppableId;
    const destinationList = result.destination.droppableId;

    if (sourceList === "shownTabs" && destinationList === "shownTabs") {
      const reorderedTabs = reorder(
        profile_tabs,
        result.source.index,
        result.destination.index
      );

      onChange({target: {name: 'profile_tabs', value: reorderedTabs}});
    }

    if (sourceList === "shownTabs" && destinationList === "removedTabs") {
      const movedTab = profile_tabs[result.source.index];
      if (movedTab === "about") {
        handleError("Cannot remove About section from your profile");
        return;
      }
      const updatedProfileTabs = [...profile_tabs];
      updatedProfileTabs.splice(result.source.index, 1);

      setRemovedTabs([...removedTabs, movedTab]);
      onChange({target: {name: 'profile_tabs', value: updatedProfileTabs}});
    }

    if (sourceList === "removedTabs" && destinationList === "shownTabs") {
      const removedTab = removedTabs[result.source.index];
      const updatedRemovedTabs = [...removedTabs];
      updatedRemovedTabs.splice(result.source.index, 1);

      setRemovedTabs(updatedRemovedTabs);

      onChange({target: {name: 'profile_tabs', value: [...profile_tabs, removedTab]}});
    }
  };

  return (
      <FormSection>    
      <div className="drag-drop-container">
        <DragDropContext onDragEnd={handleDragEnd} >
            <DraggableList
              items={profile_tabs}
              droppableId={'shownTabs'}
              title="Shown Sections in Profile"
            />
            <DraggableList
              items={removedTabs}
              droppableId={'removedTabs'}
              title="Hidden Sections in Profile"
            />
        </DragDropContext>
      </div>

      <Label secondary padded>
        Drag and drop the items to adjust the order of the tabs shown in your profile. <br />
        Additionally, you can hide sections from your profile by moving the items to the right column.
      </Label>

      {profile_tabs.length > 5 && 
        <Alert severity="warning"
          sx={{marginBottom: "20px"}}>
          Our menu layout is designed to display up to 5 tabs simultaneously on all screens. If more than 5 tabs are selected, they may overlap. We recommend testing your layout to ensure it meets your needs while we work on a solution.
        </Alert>}
      
    </FormSection> 
  );
};

TabsOrganizer.propTypes = {
  profile_tabs: PropTypes.object,
  onChange: PropTypes.func,
  onUnsavedChanges: PropTypes.func
};

export default TabsOrganizer;
