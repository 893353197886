import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from 'ui/Button';
import Socials from 'containers/public/Profile/Body/About/Socials';

import logo from 'assets/images/logo-membrzclub.png';
import './style.css';
import { TextField } from '@mui/material';
import FormSection from 'ui/FormSection';

const socials = [
  {id: 1, content: 'https://membrz.club', require_telegram: false, require_whatsapp: false, element_type: 'website'},
  {id: 2, content: 'https://www.instagram.com/membrz.club/', require_telegram: false, require_whatsapp: false, element_type: 'instagram'},
  {id: 3, content: 'https://www.tiktok.com/@membrz.club', require_telegram: false, require_whatsapp: false, element_type: 'tiktok'},
  // {id: 4, content: 'https://membrz.club/organizations/11/memberships', element_type: 'x'},
]

const ErrorPage = ({page}) => {

  const {title, description, primaryButtonLabel = 'Back', formFieldLabel = '', formFieldValue = null, onFormFieldValueChange = null, secondaryButtonLabel, secondaryButtonAction} = page;

  return (
    <Box
        className='error-page'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >

      <div className='info grid'>
        <div className='logo'>
          <img src={logo} alt="Membrz.Club"/>
        </div>
        <div className='error'>
          <h1>{title}</h1>
          <p>{description}</p>
          
          {formFieldLabel && 
            <FormSection slim>
              <TextField
                fullWidth
                color='warning'
                size='small'
                label={formFieldLabel}
                value={formFieldValue}
                onChange={onFormFieldValueChange}
                >
              </TextField>
            </FormSection>}

          <div className='new-form-field-footer'> 
            <Button fullwidth
                    secondary
                    onClick={() => window.history.back()} >
              {primaryButtonLabel}
            </Button>
            {secondaryButtonAction && 
              <Button fullwidth
                      onClick={secondaryButtonAction}
                      highlighted={secondaryButtonLabel === 'Claim your Club' || secondaryButtonLabel === 'Submit'}>
                {secondaryButtonLabel}
              </Button>}
          </div>
        </div>
      </div>
      <Box
        component="footer"
        sx={{
          py: 10,
          px: 2,
          mt: 'auto'
        }}
      >
        <Container maxWidth="md">
          <div className='grid'>
            <Typography variant="body1">
              Membrz Club { new Date().getFullYear() }
            </Typography>
            <div className='links'>
                <Socials socials={socials} />
            </div>
          </div>
        </Container>
      </Box>
    </Box>

  );
};

ErrorPage.propTypes = {
  page: PropTypes.object,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonAction: PropTypes.func
};

export default ErrorPage;
