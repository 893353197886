import React from 'react';
import PropTypes from 'prop-types';

import CardGrid from './CardGrid';

import Title from 'ui/text/Title';
import Description from 'ui/text/Description';

import { t } from "i18n/index";

const EventsList = ({events}) => {

  return (
    <div className='events-content'>
        <Title>{t('literal:events')}</Title>

        <Description secondary>{t('profile:events:pageDescription')}</Description>

        <CardGrid events={events} />
    </div>
  );
};

EventsList.propTypes = {
  events: PropTypes.array,
};

export default EventsList;
