import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Label = ({children, secondary, bold, padded, red, className}) => {

  const classes = ['ui-label'];
  if (secondary) classes.push('ui-secondary-text');
  if (bold) classes.push('ui-bold-text');
  if (padded) classes.push('ui-padded-text');
  if (red) classes.push('ui-red-text');
  if (className) classes.push(className);

  return (
    <p className={classes.join(' ')} >{children}</p>
  )
};

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  secondary: PropTypes.bool,
  bold: PropTypes.bool,
  padded: PropTypes.bool,
  className: PropTypes.string,
  red: PropTypes.bool
};

export default Label;