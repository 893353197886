import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material';

const TooltipComponent = ({message, Icon}) => {
  return (
    <Tooltip title={message}>
        <Icon color='warning'/> 
    </Tooltip>
  );
};

TooltipComponent.propTypes = {
  message: PropTypes.string,
  Icon: PropTypes.element
};

export default TooltipComponent