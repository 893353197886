import React from "react";
import PropTypes from "prop-types";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const TierSelector = ({ tier_title, tiers, coupons, onTierChange }) => {

  const unusedTiers = tiers.filter((tier) => !coupons.some((coupon) => coupon.tier_id === tier.id));

  const renderOption = (props, option) => <li {...props}>{option.title}</li>;
  const renderInput = (params) => <TextField {...params} label="Eligible Tier" color="warning" />;

  return (
    <Autocomplete
      size="small"
      selectOnFocus
      clearOnBlur
      clearOnEscape	
      handleHomeEndKeys
      freeSolo
      fullWidth
      value={tier_title}
      options={unusedTiers}
      onChange={onTierChange}
      renderOption={renderOption}
      renderInput={renderInput}
      getOptionLabel={(option) => option.title || tier_title}
    />
  );
};

TierSelector.propTypes = {
  tier_title: PropTypes.string,
  tiers: PropTypes.array,
  coupons: PropTypes.array,
  onTierChange: PropTypes.func,
};

export default TierSelector;
