import React from 'react';
import PropTypes from 'prop-types';

import StatCard from 'components/StatCard';
  
const Status = ({ tickets }) => {

  const stat3 = tickets.length;
  const stat1 = tickets.reduce((count, ticket) => ticket.completed_at ? count + 1 : count, 0);
  const stat2 = stat3 - stat1;

    return (
        <div className="event-stats-grid grid custom-margin">

            <StatCard type='number'
                color='rgb(255, 99, 132)'
                highlight={ stat1 }
                title='Checked In' />

            <StatCard type='number'
                color='rgb(54, 162, 235)'
                highlight={ stat2 }
                title='Not Checked In' />

            <StatCard type='number'
                    color='rgb(255, 205, 86)'
                    highlight={ stat3 }
                    title='Total Sold Tickets' />

        </div>
    )};

Status.propTypes = {
    tickets: PropTypes.array
};

export default Status;