export default function redirectToView(history, role, coursesList) {
    
  const manager = coursesList && coursesList.length > 0;
  
  if (role === 'admin') {
    history.push(`/admin/users`);
  } else if (manager) {
    const organizationId = coursesList[0].organization_id;
    history.push(`/organizations/${organizationId}`);
  } else {
    history.push(`/profile`);
  }
}