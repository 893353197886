import React, { useCallback, useState } from 'react';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import AdminResource from 'resources/admin/SearchableFilterResource';
import OrganizationResource from 'resources/organization/SearchableFilterResource';
import CheckinResource from 'resources/checkin/SearchableFilterResource';

import { useErrorController } from 'utils/useErrorController';

import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete, TextField } from '@mui/material';

import { debounce } from 'lodash';

const requestResource = {
  "admin": AdminResource,
  "organization": OrganizationResource,
  "checkin": CheckinResource
}

const getRequestParams = (query, label, organizationId, eventUuid) => {

  const commonParams = {
    query: query,
    entity: label
  };

  if (organizationId) {
    commonParams.organization_id = organizationId;
  }

  if (eventUuid) {
    commonParams.event_uuid = eventUuid;
  }

  return commonParams;
};

const SearchableFilter = ({ label, onChange }) => {

  const [options, setOptions] = useState([])
  const {loading, setLoading, handleError} = useErrorController();

  const params = useParams();
  const organizationId = params.organizationId ? parseInt(params.organizationId) : null;
  const eventUuid = params.eventUuid;

  const context = organizationId ? "organization" : eventUuid ? "checkin" : "admin";

  const renderOption = (props, option) => <li {...props}>{option.identifier}</li>;
  const renderInput = (params) => 
    <TextField 
      {...params} 
      label={`Filter by ${label}`} 
      color="warning"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />;

  const {fetch} = useController();

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      try {
        const response = await fetch(requestResource[context].list(), getRequestParams(query, label, organizationId, eventUuid));
        setOptions(response);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    }, 1000),
    [fetch, context, label]
  );

  const handleSelection = (event, newValue) => {
    onChange(`${label}_id`, newValue?.id);
  };

  const handleQueryChange = (event, newValue, reason) => {
    if (reason === 'input') {
      setLoading(true);
      debouncedSearch(newValue);
    }
  };

  const handleOpen = () => {
    setLoading(true);
    debouncedSearch('');
  };

  return (
    <Autocomplete
      size="small"
      selectOnFocus
      blurOnSelect
      clearOnBlur
      clearOnEscape
      handleHomeEndKeys
      openOnFocus
      loading={loading}
      sx={{ width: 240 }}
      options={options}
      onChange={handleSelection}
      onOpen={handleOpen}
      onInputChange={handleQueryChange}
      renderOption={renderOption}
      renderInput={renderInput}
      getOptionLabel={(option) => option.identifier}
    />
  );
};

SearchableFilter.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func
};

export default SearchableFilter;
