import React from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import StripeConnectResource from 'resources/organization/StripeConnectResource';

import Button from 'ui/Button'
import Description from 'ui/text/Description';
import FormSection from 'ui/FormSection';

import customerPortalImg from 'assets/images/stripe_customer_portal.png';

import { useError } from 'utils/useErrorController';
import useOrganizationAdmin from 'utils/useOrganizationAdmin';

import { Alert } from '@mui/material';

const customerPortalUrl = 'https://dashboard.stripe.com/settings/billing/portal';

const instructions = {
    notStarted: "To start receiving payments via Membrz.Club for Tickets, Products, and Memberships, we need you to set up and verify your account. We partner with Stripe to provide that service. It's a simple and easy-to-follow process. If you already have a Stripe account, you can connect your existing one.",
    restricted: "Thank you for taking the first step to start receiving payments via Membrz.Club for Tickets, Products, and Memberships. Please click the button below to complete your account verification.",
    enabled: "Thank you for verifying your account. You can now receive payments via Membrz.Club for Tickets, Products, and Memberships. Additional information may be required after reaching certain volume thresholds; complete verification now or wait until it’s due.",
    completed: "Thank you for fully verifying your account. You can now receive payments via Membrz.Club for Tickets, Products, and Memberships.",
};

const buttonLabels = {
    notStarted: 'Start Verification',
    restricted: 'Continue Verification',
    enabled: 'Complete Verification',
    completed: 'See Account Details'
};

const getAccountStatus = (account_id, charges_enabled, requirements_due) => {
    if (!account_id) return 'notStarted';
    if (!charges_enabled) return 'restricted'
    if (charges_enabled && requirements_due) return 'enabled';
    if (!requirements_due) return 'completed'
};

const Stripe = () => {

    const { fetch } = useController();

    const params = useParams();
    const organizationId = parseInt(params.organizationId);

    const organization = useOrganizationAdmin();

    const { account_id, charges_enabled, requirements_due, can_reconnect } = useSuspense(StripeConnectResource.detail(), {organization_id: organizationId, id: organizationId})

    const { loading, setLoading, handleError } = useError();

    const handleClick = async () => {
        try {
            setLoading(true);

            const { url } = await fetch(StripeConnectResource.create(), {organization_id: organizationId}, {});
            window.location.href = url;

            setLoading(false);
        } catch (error) {
            handleError(error);
        }
    };

    const status = getAccountStatus(account_id, charges_enabled, requirements_due);

    const showCustomerPortalPrompt = charges_enabled && organization.country == 'BR';

    return (
        <div>
            <div className='stripe-connect-form'>
                <FormSection title={status === 'completed' ? 'Stripe Connected Successfully ✅' : 'Set up and verify your account'}>
                    <Description>{instructions[status]}</Description>
                </FormSection>

                <div  className='new-form-field-footer'>
                    {status !== 'completed' && <Button loading={loading} minwidth={loading} onClick={ handleClick }>{buttonLabels[status]}</Button>}
                    {can_reconnect && <Button loading={loading} minwidth onClick={ handleClick } >Reconnect</Button>}
                </div>

                {showCustomerPortalPrompt &&
                    <Alert severity="info" sx={{ mr: '10px', ml: '10px' }}>
                        <div  className='alert'>
                            Please ensure that you have activated the link to your Stripe Customer Portal within the correct organization on Stripe. This will enable users to manage their payment details and subscriptions.
                            <img src={customerPortalImg} />
                            <Button loading={loading} onClick={() => window.open(customerPortalUrl, '_blank')} highlighted>Check Customer Portal</Button>
                        </div>
                    </Alert>
                }
            </div>
        </div>
    );
};
export default Stripe
