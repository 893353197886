import React from 'react';
import PropTypes from 'prop-types';

import Card from 'ui/Card';

import CardHeader from './CardHeader';
import ButtonGroup from './ButtonGroup';

const CardGrid = ({ events }) => {

  return (
    <div className='grid'>
        { events.map((event) => {
          const { id, title, start_date, end_date, location, image_url, external_url, slug, short_description} = event;
            return (
            <Card
              cropped={true}
              key={ id }
              image_url={ image_url }

              header={<CardHeader
                        title={title}
                        start_date={start_date}
                        end_date={end_date}
                        location={location}
                        grid={true}
                    />}

              description={short_description}

              footer={<ButtonGroup
                        external_url={external_url}
                        slug={slug}
                      />}
            />
        )})}
    </div>
  );
};

CardGrid.propTypes = {
    events: PropTypes.array,
};

export default CardGrid;
