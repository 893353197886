import React from 'react';
import PropTypes from 'prop-types';

import ViewMore from './ViewMore';
import RedeemButton from './RedeemButton';
import BuyButton from './BuyButton';

import Label from 'ui/text/Label';

import { convertPriceFromCents, currencySymbol } from 'utils/rewards';

import { t } from "i18n/index";
import { useError } from 'utils/useErrorController';
import { offerCategory } from 'utils/offersFormHelper';

const ButtonGroup = ({ product, onAction, onClick}) => {

  const { points = 0, available, redeemed_count, display_price, currency, discount_percentage, redeem_limit, category} = product;

  const showDescription = redeemed_count > 0;
  const showRedeemButton = !display_price || points > 0;
  const redeemedMax = redeem_limit && redeemed_count >= redeem_limit
  const disableRedeemButton = redeemedMax || !available;
  const disableBuyButton = redeemedMax || !available;
  const showBuyButton = display_price && currency;
  const isTicket = category === offerCategory.ticket.value;

  const convertedPrice = convertPriceFromCents(display_price);
  const priceWithCurrency = `${parseFloat(convertedPrice)} ${currencySymbol[currency]}`;

  const {loading} = useError();

  return (
    <div className='button-group'>
      {onClick && <ViewMore handleRedirect={onClick}/>}

      {showRedeemButton &&
        <RedeemButton
          onAction={() => onAction(product, 'points')}
          loading={loading}
          disableRedeemButton={disableRedeemButton}
          points={points}
          available={available}
          isTicket={isTicket}
        />
      }

      {showBuyButton &&
        <BuyButton
          onAction={() => onAction(product, priceWithCurrency)}
          loading={loading}
          disableBuyButton={disableBuyButton}
          priceWithCurrency={priceWithCurrency}
          discount_percentage={discount_percentage}
          available={available}
        />
      }

      {showDescription &&
        <Label secondary small>
          {redeemedMax ? t('errors:redeemedMax') : `${t('profile:perks:redeemedCountMessage')} ${redeemed_count > 1 ? redeemed_count + " " + t('literal:times_sc') : t('literal:once_sc')}`}
        </Label>
      }
    </div>
  );
};

ButtonGroup.propTypes = {
    product: PropTypes.object,
    onAction: PropTypes.func,
    onClick: PropTypes.func,
};

export default ButtonGroup;
