import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import FormSection from 'ui/FormSection';
import Button from 'ui/Button';
import Description from 'ui/text/Description';

import ConfirmModal from 'components/ConfirmModal';


import copyToClipboard from 'utils/copyToClipboard';

import { IconButton, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RedeemIcon from '@mui/icons-material/Redeem';
import { useToast } from 'utils/context/ToastContext';

const Tickets = ({showTicketing, tickets, unsavedChanges, setUnsavedChanges}) => {
    if (!showTicketing) return null;

    const [confirmModal, setConfirmModal] = useState(false);
    const [redirectTarget, setRedirectTarget] = useState(null);

    const params = useParams();
    const organizationId = parseInt(params.organizationId);
    const eventId = parseInt(params.eventId);

    const history = useHistory();

    const handleRedirect = (e, target) => {
        e.preventDefault();
        if (unsavedChanges) {
            setConfirmModal(true);
            setRedirectTarget(target);
        } else {
            history.push(`/organizations/${organizationId}/perks${target}`);
        }
    };

    const handleDiscardChanges = () => {
        setConfirmModal(false);
        setUnsavedChanges(false);
        setRedirectTarget(null);
        history.push(`/organizations/${organizationId}/perks${redirectTarget}`);
    };

    const { setOpen, setMessage, setSeverity } = useToast();
    const handleUrlCopy = (external_url) => {
        copyToClipboard(external_url, setOpen, setSeverity, setMessage)
      };

  return (
    <>
        <FormSection title={"Tickets"}>
            <Stack spacing={1}>
                {tickets && tickets.map((ticket, index) => (
                    <div className={'event-form_ticket'} key={index}>
                        <Description>{ticket.identifier}</Description>
                        <div>
                            <IconButton onClick={() => handleUrlCopy(ticket.external_url)}><LinkIcon color='warning'/></IconButton>
                            <IconButton onClick={(e) => handleRedirect(e, `/${ticket.id}/edit`)}><EditIcon color='warning'/></IconButton>
                        </div>
                    </div>
                ))}
                { !!eventId &&
                <div className={'event-form_ticket center'}>
                    <Description>Add New Ticket</Description>
                    <IconButton onClick={(e) => handleRedirect(e, `/new?eventId=${eventId}`)}><AddCircleOutlineIcon color='warning'/></IconButton>
                </div>
                }
            </Stack>
            <Button
                small
                text
                icon={RedeemIcon}
                disabled={!eventId}
                onClick={(e) => handleRedirect(e, "")}>
                {eventId ? 'Tickets can be reordered here.' : 'Tickets creation will be enabled after saving the event.'}
            </Button>
        </FormSection>
        {confirmModal &&
            <ConfirmModal
                title={'Discard changes?'}
                open={confirmModal}
                setOpen={setConfirmModal}
                onConfirm={() => handleDiscardChanges()}
            >
                You have unsaved changes. Moving pages now will cause these to be lost. Are you sure you want to continue?
            </ConfirmModal>}
    </>
)};

Tickets.propTypes = {
    showTicketing: PropTypes.bool,
    tickets: PropTypes.array,
    unsavedChanges: PropTypes.bool,
    setUnsavedChanges: PropTypes.func,
    eventId: PropTypes.number
};

export default Tickets;
