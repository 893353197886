import SearchOrderInstanceResource from "resources/organization/SearchOrderInstanceResource";
import MembershipResource from "resources/Profile/MembershipResource";
import ProfileResource from "resources/Profile/ProfileResource";
import ProductResource from "resources/Profile/ProductResource";

import { t } from "i18n/index";

export const findOrderResult = (
  paymentIntentId,
  allowedAttempts,
  requestInterval,
  fetch,
  handleStep,
  handleError,
  handleLoading
) => {
  let attempts = 0;
  const poll = setInterval(async () => {
    try {
      const response = await fetch(SearchOrderInstanceResource.detail(), {
        organization_id: 1,
        id: paymentIntentId,
      });

      if (response.order_id === response.order_id_main) {
        clearInterval(poll);
        await fetch(MembershipResource.list(), {
          organization_slug: response.organization_slug,
        });
        await fetch(ProfileResource.detail(), {
          id: response.organization_slug,
        });
        await fetch(ProductResource.list(), {
          organization_slug: response.organization_slug,
        })
        handleStep(2);
        handleLoading(false);

      } else if (attempts >= allowedAttempts) {
        handleError(t("errors:default"));
        clearInterval(poll);
        handleLoading(false);

      } else {
        attempts++;

      }
    } catch (error) {
      if (attempts >= allowedAttempts) {
        handleError(t("errors:default"));
        clearInterval(poll);

      } else {
        attempts++;

      }
    }
  }, requestInterval);
};
