import React from 'react';
import PropTypes from 'prop-types';

import SearchableFilter from 'ui/SearchableFilter';

const ProductFilter = ({ onChange }) => {

  return (
    <div className='filter-group'>
        <SearchableFilter
          label={"organization"}
          onChange={onChange}
        />
        <SearchableFilter
          label={"product"}
          onChange={onChange}
        />
    </div>
  );
};

ProductFilter.propTypes = {
  onChange: PropTypes.func,
};

export default ProductFilter;
